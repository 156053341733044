<template>
    <div class="alert-box" :class="classes(alert)">
        <h3 v-if="alert.title">{{ alert.title }}</h3>
        <template v-if="typeof alert.content === 'object'">
            <template>{{ alert.content.message }}</template>
            <ul v-if="alert.content.reasons">
                <li v-for="reason in alert.content.reasons">{{ reason }}</li>
            </ul>
        </template>
        <template v-if="typeof alert.content !== 'object' && alert.content !== ''">{{ alert.content }}</template>
        <button v-if="alert.dismissable" type="button" @click="hide(alert.id)" class="close"><i class="fa fa-times"></i></button>
    </div>
</template>

<script>

    export default {
        name: 'alert-box',
        props: ['alert'],
        mounted() {
            this.timeout()
        },
        updated() {
            this.timeout()
        },
        methods: {
            /**
             * Hides alert
             *
             * @param {string} id
             */
            hide(id) {
                this.$emit('hide', id)
            },
            /**
             * Adds the css classes
             *
             * @param {object} alert
             * @returns {string}
             */
            classes(alert) {
                let classes = [alert.type]
                if (alert.dismissable) {
                    classes.push('dismissable')
                }
                return classes.join(' ')
            },
            /**
             * Runs the timeout on the alert
             */
            timeout() {
                if (this.alert.timeout) {
                    const timeout = typeof this.alert.timeout === 'number' ? this.alert.timeout : 3000
                    setTimeout(() => {
                        this.hide(this.alert.id)
                    }, timeout)
                }
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import "../../sass/variables", "../../sass/mixins";

    .alert-box {
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 3px;
        @include shadow;
        > * {
            color: inherit;
            transition: inherit;
            margin: 4px 0;
        }
        h3 {
            font-size: 18px;
            font-weight: bold;
        }
        p {
            margin: 4px 0;
        }
        ul {
            list-style: disc;
            margin: 4px 0;
            padding-left: 20px;
            li {
                font-size: 15px;
                font-weight: normal;
            }
        }
        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 0.75rem 1.25rem;
            color: inherit;
            &:hover {
                opacity: 1;
            }
            &:focus {
                outline: none;
            }
        }
        &.info {
            @include alert-color(#24426c);
            background-color: #dae5f5;
            border-color: #cbdbf2;
        }
        &.success {
            color: #316100;
            background-color: #dff1cc;
            border-color: #d2ecb8;
        }
        &.warning {
            color: #7d6608;
            background-color: #fcf3cf;
            border-color: #fbeebc;
        }
        &.danger,
        &.error {
            color: #6b1110;
            background-color: #f5d2d2;
            border-color: #f1c1c0;
        }
        &.dismissable {
            padding-right: 32px;
        }
    }
    .fade {
        &-enter-active,
        &-leave-active {
            transform-origin: top;
            transition: transform 240ms, opacity 240ms;
        }
        &-enter {
            transform: translateY(32px);
            opacity: 0;
        }
        &-leave-to {
            transform: translateY(-32px);
            opacity: 0;
        }
    }
</style>

<style lang="scss">
    @import "../../sass/variables";

    .alerts-wrapper {
        position: fixed;
        width: auto;
        top: 60px;
        right: 8px;
        left: 8px;
        z-index: $z-index-ajax-alerts;
        @media (min-width: $screen-sm) {
            max-width: 400px;
            top: 68px;
            right: 0;
            left: 0;
            margin: 0 auto;
        }
    }
</style>