import './utils/_plugins'
import './components/_components'
import './event-bus'
import UserProfile from './pages/users/UserProfile.vue';
import SlideOut from "./theme/HeaderSlideOut";
import Search from './theme/Search.vue';
import Notifications from "./theme/Notifications";
import Notices from "./theme/Notices";
import Header from "./theme/Header";
import Menu from "./theme/Menu";

/**
 * Theme Components
 */
Vue.component('app-header', Header)
Vue.component('app-menu', Menu)
Vue.component('app-notices', Notices)
Vue.component('app-search', Search)
Vue.component('app-notifications', Notifications)
Vue.component('header-slide-out', SlideOut)

/**
 * Pages
 */
Vue.component('user-profile', UserProfile)
