<template>
    <div class="per-page-toggle">
        <div class="count">{{ pageCount }}</div>
        <div v-if="list.length > perPage" class="toggle">
            <button type="button" @click="pageDown()" ref="pageDown" :disabled="disabled('down')"><i class="fa fa-chevron-left"></i></button>
            <button type="button" @click="pageUp()" ref="pageUp" :disabled="disabled('up')"><i class="fa fa-chevron-right"></i></button>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'list',
            'perPage',
            'currentPage'
        ],
        methods: {
            /**
             * Page Up
             */
            pageUp() {
                this.$emit('up')
                this.$refs.pageUp.blur()
            },
            /**
             * Page Down
             */
            pageDown() {
                this.$emit('down')
                this.$refs.pageDown.blur()
            },
            /**
             * Disables toggle when on first or last page
             * @param direction
             * @returns {boolean}
             */
            disabled(direction) {
                switch (direction) {
                    case 'up':
                        return this.list.length / this.perPage <= this.currentPage + 1
                    case 'down':
                        return this.currentPage === 0
                }
            }
        },
        computed: {
            /**
             * Calculates current page count & total (eg, 1-20 of 150)
             * @returns {string}
             */
            pageCount() {
                let currentPage = this.currentPage,
                    perPage = this.perPage,
                    length = this.list.length
                return (((currentPage + 1) * perPage) - perPage) + 1 +
                    ' -' +
                    (((currentPage + 1) * perPage) > length ? length : ((currentPage + 1) * perPage)) +
                    ' of ' +
                    length
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../sass/variables", "../../sass/mixins";

    .per-page-toggle {
        text-align: right;
        .count {
            display: inline-block;
            vertical-align: top;
            line-height: 24px;
            padding: 4px 0;
        }
        .toggle {
            display: inline-block;
            vertical-align: top;
            font-size: 0;
        }
        button {
            @include button-light;
            &:focus,
            &:active {
                z-index: 3;
            }
            .fa {
                vertical-align: -1px;
            }
            &:first-child {
                padding-left: 10px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                z-index: 1;
            }
            &:last-child {
                margin-left: -1px;
                padding-right: 10px;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                z-index: 2;
            }
        }
    }
</style>