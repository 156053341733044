import ClickOutside from 'vue-click-outside'
import Translation from './translate'

Vue.directive('click-out', ClickOutside)
Vue.use(Translation)

export default {
    directives: {
        ClickOutside
    }
}
