<template>
    <div class="member-search" :class="{ 'has-results': showResults }">
        <input type="text" v-model="keywords" @keyup="search()" ref="input" :placeholder="setPlaceholder">

        <transition name="search-animate">
            <div v-if="!keywords.length" class="action">
                <i class="fa fa-search"></i>
            </div>
        </transition>
        <transition name="search-animate">
            <button v-if="keywords.length" type="button" @click="clear()" class="action">
                <i class="fa fa-times"></i>
            </button>
        </transition>

        <div v-if="showResults" class="results-wrapper">
            <ul class="results">
                <li v-for="result in results" @click="select(result)" :class="{ 'added': added(result) }">
                    <img :src="result.pic">
                    <div>{{ result.firstName }} {{ result.lastName }}</div>
                </li>
                <li v-if="keywords.length > 1 && !results.length && !searching" class="empty">No Results</li>
                <li v-if="searching" class="searching">
                    <i class="fa fa-spin fa-spinner"></i> Searching
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import _debounce from 'lodash/debounce'
    import _some from 'lodash/some'
    import _unescape from 'lodash/unescape'

    export default {
        name: 'people-search',
        props: {
            list: Array,
            config: Object,
            placeholder: String
        },
        data() {
            return {
                keywords: '',
                keywordsPrevious: '',
                resultsRaw: [],
                searching: false
            }
        },
        mounted() {
            if (this.doFocus) {
                this.focusInput()
            }
        },
        methods: {
            /**
             * Focuses the element from the parent model
             */
            focus() {
                this.$refs.input.focus()
            },
            /**
             * Clears search input & results
             */
            clear() {
                this.keywords = ''
                this.searching = false
                this.resultsRaw = []
                this.focusInput()
            },
            /**
             * Triggers the search
             */
            search() {
                if (this.keywordsPrevious === this.keywords) {
                    return
                }
                this.resultsRaw = []
                if (this.keywords.length > 1) {
                    this.searching = true
                    this.doSearch()
                }
            },
            /**
             * Selects the person and adds to list
             *
             * @param {object} member
             */
            select(member) {
                if (!this.added(member)) {
                    this.$emit('add', member)
                }
                this.clear()
            },
            /**
             * Returns true if the person has already been added
             *
             * @param {object} result
             */
            added(result) {
                return _some(this.list, { memberId: result.memberId })
            },
            /**
             * Focuses input
             */
            focusInput() {
                if (this.doFocus) {
                    this.$nextTick(() => {
                        this.$refs.input.focus()
                    })
                }
            },
            /**
             * Performs a debounced search
             */
            doSearch: _debounce(function() {
                this.keywordsPrevious = this.keywords
                axios
                    .post(
                        '/users/search',
                        'term=' + encodeURI(this.keywords)
                    )
                    .then(response => {
                        this.resultsRaw = response.data
                        this.searching = false
                    })
            }, 500)
        },
        computed: {
            /**
             * Checks whether to show results box
             *
             * @returns {boolean}
             */
            showResults() {
                return this.resultsRaw.length || this.keywords.length > 1 || this.searching
            },
            /**
             * Sets the placeholder from prop or defaults to 'search'
             *
             * @returns {string}
             */
            setPlaceholder() {
                return this.placeholder ? this.placeholder : 'Search'
            },
            /**
             * Sets focus after adding
             *
             * @returns {boolean|null}
             */
            doFocus() {
                return this.config ? this.config.focus : true
            },
            /**
             * Unescapes safe characters returned from api
             *
             * @returns {array}
             */
            results() {
                return this.resultsRaw.map(result => {
                    return {
                        firstName: _unescape(result.firstName),
                        lastName: _unescape(result.lastName),
                        preferredName: _unescape(result.preferredName),
                        memberId: result.memberId,
                        pic: result.picture
                    }
                })
            }
        }
    }

</script>

<style lang="scss" scoped>
    @import "../../sass/variables", "../../sass/mixins";

    .member-search {
        position: relative;
        display: inline-block;
        vertical-align: top;
        font-size: $font-size;
        line-height: 1.5;

        &.has-results {
            z-index: $z-index-wrapper + 10;
        }

        &.block {
            display: block;
        }

        input {
            @include form-input;
            padding-right: 32px;
        }

        .search {
            &-animate {
                &-enter-active,
                &-leave-active {
                    transition: opacity 240ms, transform 240ms;
                }

                &-enter,
                &-leave-to {
                    opacity: 0;
                    transform: scale(0);
                }
            }
        }

        .action {
            position: absolute;
            top: 5px;
            right: 5px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            padding: 0;
            text-align: center;
            color: $grey-light;
            background-color: transparent;
            border: none;
            outline: none;

            &[type="button"] {
                color: $red;
                font-size: 18px;
            }
        }

        .results {
            margin: 0 0 100px;
            padding: 0;
            background-color: white;
            list-style: none;
            @include shadow;

            &-wrapper {
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                margin-top: 2px;
            }

            li {
                position: relative;
                margin: 0;
                padding: 4px 8px 4px 44px;
                min-height: 40px;
                cursor: pointer;

                img {
                    position: absolute;
                    top: 4px;
                    left: 4px;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    transition: opacity 150ms;
                }

                div {
                    margin-top: 4px;
                    line-height: 24px;
                    transition: opacity 150ms;
                }

                & + li {
                    border-top: 1px solid $form-border;
                }

                &:hover {
                    background-color: $pale;
                }

                &.added {
                    cursor: default;
                    img,
                    div {
                        opacity: 0.4;
                    }
                }

                &.searching,
                &.empty {
                    min-height: 0;
                    padding: 8px;
                    text-align: center;
                    font-size: 16px;

                    [data-icon] {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
</style>
