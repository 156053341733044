<template>
    <div class="spinner-box">
        <div class="app-spinner" :style="spinner_style"></div>
        <div class="app-spinner-text" :style="text_style" v-if="message.length > 0">{{message}}</div>
    </div>
</template>

<script>
    const isNumber = (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n)
    }
    export default {
        name: 'loading-spinner',
        props: {
            'size': {
                // either a number (pixel width/height) or 'tiny', 'small',
                // 'medium', 'large', 'huge', 'massive' for common sizes
                default: 32
            },
            'line-size': {
                type: Number,
                default: 3
            },
            'line-bg-color': {
                type: String,
                default: '#eee'
            },
            'line-fg-color': {
                type: String,
                default: '#2196f3' // match .blue color to Material Design's 'Blue 500' color
            },
            'speed': {
                type: Number,
                default: 0.8
            },
            'spacing': {
                type: Number,
                default: 4
            },
            'message': {
                type: String,
                default: Vue.translate('loading')
            },
            'font-size': {
                type: Number,
                default: 15
            },
            'text-fg-color': {
                type: String,
                default: '#111111'
            }
        },
        computed: {
            size_px () {
                switch (this.size) {
                    case 'tiny':
                        return 12
                    case 'small':
                        return 16
                    case 'medium':
                        return 32
                    case 'large':
                        return 48
                    case 'big':
                        return 64
                    case 'huge':
                        return 96
                    case 'massive':
                        return 128
                }
                return isNumber(this.size) ? this.size : 32
            },
            line_size_px () {
                switch (this.size) {
                    case 'tiny':
                        return 1
                    case 'small':
                        return 2
                    case 'medium':
                        return 3
                    case 'large':
                        return 3
                    case 'big':
                        return 4
                    case 'huge':
                        return 4
                    case 'massive':
                        return 5
                }
                return isNumber(this.lineSize) ? this.lineSize : 4
            },
            text_margin_top () {
                switch (this.size) {
                    case 'tiny':
                    case 'small':
                    case 'medium':
                    case 'large':
                    case 'big':
                    case 'huge':
                    case 'massive':
                        return Math.min(Math.max(Math.ceil(this.size_px / 8), 3), 12)
                }
                return isNumber(this.spacing) ? this.spacing : 4
            },
            text_font_size () {
                switch (this.size) {
                    case 'tiny':
                    case 'small':
                    case 'medium':
                    case 'large':
                    case 'big':
                    case 'huge':
                    case 'massive':
                        return Math.min(Math.max(Math.ceil(this.size_px * 0.4), 11), 32)
                }
                return isNumber(this.fontSize) ? this.fontSize : 13
            },
            spinner_style () {
                return {
                    'margin': '0 auto',
                    'border-radius': '100%',
                    'border': `${this.line_size_px}px solid ${this.lineBgColor}`,
                    'border-top': `${this.line_size_px}px solid ${this.lineFgColor}`,
                    'width': `${this.size_px}px`,
                    'height': `${this.size_px}px`,
                    'animation': `app-spinner-spin ${this.speed}s linear infinite`
                }
            },
            text_style () {
                return {
                    'margin-top': `${this.text_margin_top}px`,
                    'color': this.textFgColor,
                    'font-size': `${this.text_font_size}px`,
                    'text-align': 'center',
                    'padding-top': '15px'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../sass/variables";

    .spinner-box {
        position: fixed;
        left: 50%;
        top: 50%;
        z-index: $z-index-ajax-alerts;
        margin: -75px 0 0 -75px;
        border: 16px solid $blue-astronaut;
        border-radius: 50%;
        border-top: 16px solid $green-blue;
        width: 120px;
        height: 120px;
    }
    .spinner-box:before {
        content: '';
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3);
    }
    /* :not(:required) hides these rules from IE9 and below */
    .spinner-box:not(:required) {
        /* hide "loading..." text */
        font: 0/0 a;
        color: transparent;
        text-shadow: none;
        background-color: transparent;
        border: 0;
    }
</style>

<style>
    .app-spinner {
        transition: all 0.3s linear;
    }
    @keyframes app-spinner-spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
</style>
