<template>
    <div class="cp-header__topbar-item cp-header__topbar-item--user">
        <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl" x-placement="bottom-end" style="position: absolute; transform: translate3d(723px, 64px, 0px); top: 0px; left: 0px; will-change: transform;">
            <!--begin: Head -->
            <div class="cp-user-card cp-user-card--skin-dark cp-notification-item-padding-x" :style="headerStyles">
                <div class="cp-user-card__avatar">
                    <img v-if="currentUser.pic" alt="Pic" :src="currentUser.pic">
                    <span v-else class="cp-badge cp-badge--lg cp-badge--rounded cp-badge--bold cp-font-success">
                        {{ currentUser.initials }}
                    </span>
                </div>
                <div class="cp-user-card__name">
                    {{ currentUser.firstName }} {{ currentUser.lastName }}
                </div>
                <div v-if="billingData && billingData.overDuePayment" class="cp-user-card__badge">
                    <span class="btn btn-danger btn-sm btn-bold btn-font-md">{{ 'paymentDue' | translate }}</span>
                </div>
            </div>
            <!--end: Head -->

            <!--begin: Navigation -->
            <div class="cp-notification">
                <a v-if="!currentUser.customer" :href="appLinks.userMenu.profile" class="cp-notification__item">
                    <div class="cp-notification__item-icon">
                        <i class="flaticon2-calendar-3 cp-font-success"></i>
                    </div>
                    <div class="cp-notification__item-details">
                        <div class="cp-notification__item-title cp-font-bold">
                           {{ 'profile' | translate }}
                        </div>
                        <div class="cp-notification__item-time">
                            {{ 'personalSettings' | translate }}
                        </div>
                    </div>
                </a>

                <a v-if="currentUser.customer" :href="appLinks.userMenu.dashboard" class="cp-notification__item">
                    <div class="cp-notification__item-icon">
                        <i class="flaticon2-dashboard cp-font-success"></i>
                    </div>
                    <div class="cp-notification__item-details">
                        <div class="cp-notification__item-title cp-font-bold">
                            {{ 'dashboard' | translate }}
                        </div>
                        <div class="cp-notification__item-time">
                            {{ 'podcastDashboard' | translate }}
                        </div>
                    </div>
                </a>

                <a v-if="currentUser.customer" :href="appLinks.userMenu.billing" class="cp-notification__item">
                    <div class="cp-notification__item-icon">
                        <i class="flaticon2-cardiogram cp-font-warning"></i>
                    </div>
                    <div class="cp-notification__item-details">
                        <div class="cp-notification__item-title cp-font-bold">
                            {{ 'billing' | translate }}
                        </div>
                        <div class="cp-notification__item-time">
                            {{ 'billingStatement' | translate }}
                            <span v-if="billingNotificationCount > 0" class="cp-badge cp-badge--danger cp-badge--inline cp-badge--pill cp-badge--rounded">
                                {{ billingNotificationCount() }} {{ 'pending' | translate }}
                            </span>
                        </div>
                    </div>
                </a>

                <div v-if="!currentUser.customer" class="cp-notification__custom cp-space-between">
                    <form id="logout-form" :action="appLinks.userMenu.logout" method="POST">
                        <input type="hidden" name="_token" :value="csrfToken">
                        <button type="submit"
                           class="btn btn-label btn-label-brand btn-sm btn-bold">
                            {{ 'logOut' | translate }}
                        </button>
                    </form>
                </div>
            </div>
            <!--end: Navigation -->
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            currentUser: Object,
            appLinks: Object,
            profileData: Object,
            billingData: Object,
        },
        name: 'user-profile',
        data() {
            return {
                csrfToken: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        methods: {
            billingNotificationCount() {
                return _size(this.currentUser.notifications)
            },
        },
        computed: {
            headerStyles () {
                return {
                    'background-image': 'url(' + this.profileData.backgroundImage + ')'
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .cp-user-card {
        background-color: #1b1b28;
    }
</style>
