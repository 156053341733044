<template>

    <div class="page-table">
        <template v-if="searchable">
            <div class="search">
                <div class="search-box">
                    <input type="text" :value="term" @keyup="updateValue($event.target.value)" class="form-control" :placeholder="'search' | translate">
                    <transition name="search">
                        <div v-if="!term.length" class="icon fa fa-search"></div>
                    </transition>
                    <transition name="search">
                        <button v-if="term.length" type="button" @click="clearSearch()" class="clear">
                            <i class="fa fa-times"></i>
                        </button>
                    </transition>
                </div>
            </div>
        </template>
        <template v-if="list.length">
            <div class="page-table-controls">
                <div class="left">
                    <button type="button" v-if="canSortList" @click="toggleSort()" class="button" :disabled="savingSort" ref="toggleSort">
                        <fa v-if="savingSort" :icon="['far', 'spinner']" pulse></fa>
                        Change Order
                    </button>
                    <per-page :count="perPage" :list="list" @change="updatePerPage"></per-page>
                </div>
                <div class="right">
                    <per-page-toggle :list="list" :per-page="perPage" :current-page="currentPage" @up="pageUp" @down="pageDown"></per-page-toggle>
                </div>
            </div>

            <div :value="list" :options="dragOptions" @end="saveSort" class="list-view">
                <div v-for="item in displayList" class="item" :class="{ 'has-avatar': hasAvatar(item.id) || hasInitials(item.id), 'has-sort': showSort, 'has-actions': hasActions(item) }">

                    <transition name="sort">
                        <div v-if="showSort" class="sort arrows-alt" :class="{ 'saving': savingSort }"></div>
                    </transition>

                    <img v-if="hasAvatar(item.id)" :src="item.pic" :style="'border-color:' + item.color" class="avatar">

                    <span v-else-if="hasInitials(item.id)" class="initials cp-media cp-media--md cp-media--circle cp-media--dark">
                        <span>{{ item.initials }}</span>
                    </span>

                    <div class="name">
                        <a v-if="hasEditLink(item)" :href="item.editLink">{{ item.name }}</a>
                        <template v-else>{{ item.name }}</template>
                        <span v-if="hasLabels(item)" v-for="label in item.labels" class="label" :class="label.color">{{ label.title }}</span>
                    </div>

                    <div v-if="!desktopView && mobileActions(item)" class="actions" :class="{ 'has-toggle' : mobileActions(item).links.length }">
                        <a v-if="mobileActions(item).main.url" :href="mobileActions(item).main.url" :title="mobileActions(item).main.title" class="action" :class="mobileActions(item).main.icon"></a>
                        <button v-else type="button" @click="handleAction(mobileActions(item).main.action, item)" :title="mobileActions(item).main.title" class="action" :class="mobileActions(item).main.icon"></button>
                        <template v-if="mobileActions(item).links.length">
                            <button type="button" class="toggle" @click="toggleItem(item)"></button>
                            <transition v-if="item.open" name="actions">
                                <li v-for="link in mobileActions(item).links">
                                    <a v-if="link.url" :href="link.url">{{ link.title }}</a>
                                    <button class="action" v-else :class="link.icon" type="button" @click="handleAction(link.action, item)"></button>
                                </li>
                            </transition>
                        </template>
                    </div>

                    <div v-if="desktopView && primaryActions(item).length" class="links">
                        <template v-for="link in primaryActions(item)">
                            <a v-if="link.url" :href="link.url">{{ link.title }}</a>
                            <button v-else type="button" @click="handleAction(link.action, item)">{{ link.title }}</button>
                        </template>
                    </div>

                    <div v-if="desktopView && secondaryActions(item)" class="actions" :class="{ 'has-toggle' : secondaryActions(item).links.length }">
                        <a v-if="secondaryActions(item).main.url" :href="secondaryActions(item).main.url" class="action">{{ secondaryActions(item).main.title }}</a>
                        <button v-else type="button" @click="handleAction(secondaryActions(item).main.action, item)" class="action">{{ secondaryActions(item).main.title }}</button>
                        <template v-if="secondaryActions(item).links.length">
                            <button type="button" class="toggle" @click="toggleItem(item)"></button>
                            <transition v-if="item.open" name="actions">
                                <li v-for="link in mobileActions(item).links">
                                    <a v-if="link.url" :href="link.url">{{ link.title }}</a>
                                    <button v-else :class="link.icon" type="button" @click="handleAction(link.action, item)"></button>
                                </li>
                            </transition>
                        </template>
                    </div>

                </div>
            </div>

        </template>

        <div v-else class="notice lg">
            {{ 'noDisplay' | translate }}
        </div>

    </div>

</template>

<script>
    import PerPage from './PerPage.vue'
    import PerPageToggle from './PerPageToggle.vue'
    import {
        clone as _clone,
        debounce as _debounce,
        filter as _filter,
        find as _find,
        head as _head,
        map as _map,
        slice as _slice,
        tail as _tail,
        partialRight as _partialRight,
        every as _every,
        some as _some,
        split as _split
    } from 'lodash-es'
    export default {
        name: 'page-table',
        components: {
            PerPage,
            PerPageToggle
        },
        data() {
            return {
                currentPage: 0,
                showSort: false,
                listSortable: true,
                savingSort: false,
                dragOptions: {
                    draggable: '.has-sort',
                    handle: '.sort',
                    dragClass: 'dragging'
                },
                searchTerms: '',
                desktopView: false
            }
        },
        props: [
            'options',
            'value'
        ],
        beforeMount() {
            /**
             * Run resize
             */
            this.resize()
        },
        mounted() {
            /**
             * Start resize listener
             */
            window.addEventListener('resize', () => {
                this.doResize()
            })
        },
        methods: {
            /**
             * Filters a list of people by search terms
             *
             * @param {array} list
             * @param {string} terms
             * @returns {array}
             */
            searchList(list, terms) {
                let search = _partialRight(this.searchService, terms),
                    listInfo = item => item.name + ' ' + item.preferredName + ' ' + item.lastName
                return _filter(list, result => search(listInfo(result)))
            },
            /**
             * Returns true if the provided terms match a string
             *
             * @param {array} source
             * @param {string} terms
             * @returns {array}
             */
            searchService(source, terms) {
                let partials = _split(terms.trim(), ' '),
                    parts = _split(source.trim(), ' ')
                return _every(partials, partial => {
                    return _some(parts, part => {
                        return part.toLowerCase().indexOf(partial.toLowerCase()) > -1
                    })
                })
            },
            /**
             * Clear search
             */
            clearSearch() {
                this.searchTerms = ''
            },
            /**
             * Update search
             *
             * @param {string} value
             */
            updateValue(value) {
                this.searchTerms = value
            },
            /**
             * Toggle sorting
             */
            toggleSort() {
                this.showSort = !this.showSort
                this.$refs.toggleSort.blur()
            },
            /**
             * Save sorting
             *
             * @param {MouseEvent} event
             */
            saveSort(event) {
                let list = _clone(this.list),
                    offset = this.currentPage * this.perPage,
                    oldIndex = offset + event.oldIndex,
                    newIndex = offset + event.newIndex
                this.savingSort = true
                list.splice(newIndex, 0, list.splice(oldIndex, 1)[0])
                this.$emit('sort', _map(list, 'id'), () => {
                    this.savingSort = false
                })
                this.$emit('input', list)
            },
            /**
             * Page up
             */
            pageUp() {
                this.currentPage++
            },
            /**
             * Page down
             */
            pageDown() {
                this.currentPage--
            },
            /**
             * Updates per page count
             *
             * @param {number} count
             */
            updatePerPage(count) {
                this.$emit('action', 'perPage', count)
                this.currentPage = 0
            },
            /**
             * Does the list have avatars
             *
             * @param {string} itemId
             * @return {boolean}
             */
            hasAvatar(itemId) {
                return _find(this.list, item => {
                    return item.id === itemId &&
                        typeof item.pic !== 'undefined' && item.pic.length
                })
            },
            /**
             * Does the list have initials
             *
             * @param {string} itemId
             * @return {boolean}
             */
            hasInitials(itemId) {
                return _find(this.list, item => {
                    return item.id === itemId &&
                        typeof item.initials !== 'undefined' && item.initials.length
                })
            },
            /**
             * Does the item have a label
             *
             * @param {object} item
             * @return {boolean}
             */
            hasLabels(item) {
                return typeof item.labels !== 'undefined' && item.labels.length
            },
            /**
             * Does the item have an edit link
             *
             * @param {object} item
             * @return {boolean}
             */
            hasEditLink(item) {
                return typeof item.editLink !== 'undefined'
            },
            /**
             * Returns true if item has actions on breakpoint
             *
             * @param {object} item
             * @return {boolean}
             */
            hasActions(item) {
                if (this.desktopView) {
                    return _filter(item.links, link => {
                        return link.secondary
                    }).length
                } else {
                    return item.links.length
                }
            },
            /**
             * Gets the actions for mobile
             *
             * @param {object} item
             * @return {object|null}
             */
            mobileActions(item) {
                const links = _filter(item.links, link => {
                    if (_size(link) > 0) {
                        return link.length || (link.url !== 'undefined' || link.secondary)
                    }
                })
                return links.length ? {
                    main: _head(links),
                    links: _tail(links)
                } : null
            },
            /**
             * Gets the primary links
             *
             * @param {object} item
             * @return {object}
             */
            primaryActions(item) {
                return _filter(item.links, link => {
                    return !link.secondary
                })
            },
            /**
             * Gets the secondary links
             *
             * @param {object} item
             * @return {object|null}
             */
            secondaryActions(item) {
                const links = _filter(item.links, link => {
                    return link.secondary
                })
                return links.length ? {
                    main: _head(links),
                    links: _tail(links)
                } : null
            },
            /**
             * Toggles dropdown for item
             */
            toggleItem(item) {
                if (!item.open) {
                    this.$set(item, 'open', true)
                } else {
                    item.open = false
                }
            },
            /**
             * Emit the action on the model
             *
             * @param {string} action
             * @param {object} item
             */
            handleAction(action, item) {
                this.$emit('action', action, item)
            },
            /**
             * Checks for header & footer heights & adjusts content positioning
             */
            resize() {
                this.desktopView = window.innerWidth >= 768
            },
            /**
             * Debounces the resize method
             */
            doResize: _debounce(function() {
                this.resize()
            }, 200)
        },
        computed: {
            /**
             * Checks if page table is searchable
             */
            searchable() {
                return !!(this.options && this.options.search);
            },
            /**
             * Computed paginated list
             *
             * @return {array}
             */
            displayList() {
                let currentPage = this.currentPage,
                    perPage = this.perPage,
                    start = currentPage * perPage,
                    end = (currentPage + 1) * perPage
                return _slice(this.list, start, end)
            },
            /**
             * Get perPage from options prop
             *
             * @return {number}
             */
            perPage() {
                return (this.options && this.options.perPage) || 10
            },
            /**
             * Get the SearchTerm
             *
             * @return {string}
             */
            term() {
                return this.searchTerms
            },
            /**
             * Get list from model
             *
             * @return {string}
             */
            list() {
                let results = this.value
                if (this.searchTerms.length) {
                    results = this.searchList(results, this.searchTerms)
                    this.currentPage = 0
                }

                return results
            },
            canSortList() {
                if (this.options && this.options.sortable) {
                    this.listSortable = true
                } else {
                    this.listSortable = false
                }

                return this.listSortable
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../sass/variables", "../../sass/mixins";

    $transition: 240ms;

    .search {
        position: relative;
        margin-bottom: 8px;
        .search-box {
            margin: 10px 10px 20px;
            position: relative;
        }
        input {
            padding-right: 32px;
        }
        .icon {
            position: absolute;
            top: 0;
            right: 0;
            width: 36px;
            height: 36px;
            line-height: 36px;
            color: $grey-light;
            text-align: center;
            pointer-events: none;
        }
        .clear {
            position: absolute;
            top: 2px;
            right: 2px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            padding: 0;
            color: $red;
            background-color: transparent;
            border: none;
            border-radius: $radius;
            font-size: 18px;
            cursor: pointer;
            outline: none;
            &:focus {
                color: #fff;
                background-color: $red;
                box-shadow: 0 0 4px mix(transparent, $red);
            }
        }
        &-enter-active,
        &-leave-active {
            transition: opacity 240ms, transform 240ms;
        }
        &-enter,
        &-leave-to {
            opacity: 0;
            transform: scale(0);
        }
        @media (min-width: $screen-lg) {
            padding-right: 0;
        }
    }

    .page-table {
        padding: 8px;
        background-color: white;
        @include shadow;
        @media (min-width: $screen-sm) {
            margin-bottom: 8px;
            padding: 16px;
        }
        .button {
            @include button-light;
        }
    }

    .page-table-controls {
        .left,
        .right {
            display: inline;
            font-size: 0;
            > * {
                display: inline-block;
                vertical-align: top;
                margin-right: 8px;
                margin-bottom: 8px;
                font-size: 16px;
            }
        }
        @media (min-width: $screen-sm) {
            .left,
            .right {
                display: inline-block;
                vertical-align: top;
            }
        }
        @media (min-width: $screen-md) {
            @include clearfix;
            .left {
                float: left;
            }
            .right {
                float: right;
            }
        }
    }
    .list-view {
        .item {
            position: relative;
            margin-top: -1px;
            padding: 8px;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            transition: all $transition;
            & + .item {
                border-top-color: #eee;
            }
            &:hover {
                background-color: #f7f7f7;
                border-top-color: #ddd;
                border-bottom-color: #ddd;
                z-index: 2;
            }
            // Modifiers
            &.has-avatar {
                padding-left: 64px;
                min-height: 64px;
            }
            &.has-sort {
                padding-left: 52px;
                &.has-avatar {
                    padding-left: 108px;
                    .avatar {
                        left: 52px;
                    }
                    .initials {
                        left: 52px;
                    }
                }
            }
            &.has-actions {
                min-height: 54px;
                padding-right: 90px;
                &.has-avatar {
                    min-height: 64px;
                }
                @media (min-width: $screen-sm) {
                    padding-right: 152px;
                }
                @media (max-width: $screen-sm) {
                    min-height: 50px;
                    height: 78px;
                }
            }
            &.sortable-ghost {
                background-color: mix(white, $green, 75%);
                border-top-color: mix(white, $green);
                border-bottom-color: mix(white, $green);
                z-index: 4;
                * {
                    opacity: 0;
                }
            }
            &.dragging {
                background-color: white;
                border: none;
                cursor: move;
                opacity: 0.9 !important;
                @include shadow;
                .actions {
                    display: none;
                }
            }
        }
        .sort {
            display: inline-block;
            position: absolute;
            top: 50%;
            left: 8px;
            width: 36px;
            height: 36px;
            line-height: 36px;
            margin-top: -18px;
            text-align: center;
            font-size: 16px;
            color: $grey-light;
            transition: all $transition;
            cursor: move;
            // Animation
            &-enter,
            &-leave-to {
                opacity: 0;
                font-size: 0;
            }
            &.saving {
                opacity: 0.25;
            }
        }
        .avatar {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 2px solid $grey;
            transition: left $transition;
        }
        .initials {
            position: absolute;
            top: 8px;
            left: 8px;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 2px solid $grey;
            transition: left $transition;
        }
        .name {
            a {
                text-decoration: none;
            }
            .label {
                font-size: 11px;
                line-height: 12px;
                vertical-align: 2px;
                & + .label {
                    margin-left: 4px;
                }
                @media (max-width: $screen-sm) {
                    font-size: 7px;
                    line-height: 8px;
                    vertical-align: 2px;
                }
            }
            @media (min-width: $screen-md) {
                display: block;
                line-height: 36px;
                font-size: 20px;
            }
            font-weight: bold;
            padding-top: 10px;
        }
        .links {
            > * {
                padding: 0;
                color: $grey;
                background: transparent;
                border: none;
                line-height: 20px;
                font-size: 14px;
                font-weight: bold;
                box-shadow: none;
                &:hover {
                    text-decoration: underline;
                }
                & + * {
                    margin-left: 8px;
                }
            }
        }
        .actions {
            position: absolute;
            top: 8px;
            right: 8px;
            .action,
            .toggle {
                @include button-light;
                float: left;
                z-index: 1;
                &:focus {
                    z-index: 2;
                }
            }
            .action {
                @include text-overflow;
            }
            .toggle {
                margin-left: -1px;
                height: 32px;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                @include add-caret($right: 8px);
            }
            &.has-toggle {
                .action {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            li {
                list-style: none;
                padding: 0;
                margin: 1px 0 0;
                width: auto;
                min-width: 70px;
                max-width: 70px;
                @include shadow;
                > * {
                    display: block;
                    padding: 6px 12px;
                    color: $grey;
                    background-color: white;
                    border: none;
                    border-radius: 0;
                    width: 100%;
                    box-shadow: none;
                    text-align: center;
                    text-decoration: none;
                    font-weight: normal;
                    white-space: normal;
                    transition: all 240ms;
                    &:hover {
                        color: white;
                        background-color: $grey-light;
                    }
                }
            }
            @media (min-width: $screen-sm) {
                width: 140px;
                .action {
                    width: 100%;
                }
                &.has-toggle {
                    .action {
                        width: 115px;
                    }
                }
                ul {
                    right: 1px;
                }
            }
            &-enter-active,
            &-leave-active {
                transform-origin: 100% 0;
                transition: opacity 120ms, transform 120ms;
            }
            &-enter,
            &-leave-to {
                opacity: 0;
                transform: scale(0.75);
            }
        }
    }
</style>
