import { render, staticRenderFns } from "./AlertBox.vue?vue&type=template&id=472b934c&scoped=true&"
import script from "./AlertBox.vue?vue&type=script&lang=js&"
export * from "./AlertBox.vue?vue&type=script&lang=js&"
import style0 from "./AlertBox.vue?vue&type=style&index=0&id=472b934c&prod&lang=scss&scoped=true&"
import style1 from "./AlertBox.vue?vue&type=style&index=1&id=472b934c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "472b934c",
  null
  
)

export default component.exports