<template>
    <header-slide-out :class="'cp-quick-panel'" v-bind="slideOutData" ref="slideOut" @close="close()">
        <template v-if="loaded">
            <div class="content">
                <div class="tab-content">
                    <div v-if="notifications.length" class="tab-pane show active">

                        <div class="cp-portlet">
                            <div class="cp-portlet__body">
                                <div class="cp-notes">
                                    <div class="cp-notes__items">
                                        <div v-for="notification in notifications" :class="[notification.read ? 'cp-notification__item-read cp-notes__item' : 'cp-notes__item']">
                                            <div class="cp-notes__media">
                                                <span class="cp-notes__icon">
                                                    <template v-if="notification.type === 'user'">
                                                        <i :class="[notification.read ? 'cp-font-success' : 'cp-font-info']" class="flaticon2-user"></i>
                                                    </template>
                                                    <template v-else-if="notification.type === 'account'">
                                                        <i :class="[notification.read ? 'cp-font-success' : 'cp-font-info']" class="flaticon-paper-plane-1"></i>
                                                    </template>
                                                    <template v-else-if="notification.type === 'billing'">
                                                        <i :class="[notification.read ? 'cp-font-success' : 'cp-font-info']" class="flaticon-psd"></i>
                                                    </template>
                                                    <template v-else>
                                                        <i :class="[notification.read ? 'cp-font-success' : 'cp-font-info']" class="flaticon-bell"></i>
                                                    </template>
                                                </span>
                                            </div>
                                            <div class="cp-notes__content">
                                                <div class="cp-notes__section">
                                                    <div class="cp-notes__info">
                                                        <a :href="notification.link" class="cp-notes__title">
                                                            {{ notification.title }}
                                                        </a>
                                                        <br>
                                                        <span class="cp-notes__desc">
                                                            <span :class="[notification.read ? 'cp-badge--success' : 'cp-badge--info']" class="cp-badge cp-badge--inline">{{ notification.createdAt }}</span>
                                                        </span>
                                                    </div>
                                                    <div class="cp-notes__dropdown">
                                                        <button v-if="notification.read" type="button" ref="updateButton" :disabled="updating" @click="toggleNotificationAction(notification, 'remove')" class="btn btn-dark btn-icon">
                                                            <template v-if="updating && updatingId === notification.id">
                                                                <i class="fa fa-spin fa-spinner"></i>
                                                            </template>
                                                            <template v-else>
                                                                <i class="fa fa-trash-alt"></i>
                                                            </template>
                                                        </button>
                                                        <button v-else type="button" ref="updateButton" :disabled="updating" @click="toggleNotificationAction(notification, 'read')" class="btn btn-outline-brand btn-elevate btn-icon">
                                                            <template v-if="updating && updatingId === notification.id">
                                                                <i class="fa fa-spin fa-spinner"></i>
                                                            </template>
                                                            <template v-else>
                                                                <i class="fa fa-check"></i>
                                                            </template>
                                                        </button>
                                                    </div>
                                                </div>
                                                <span :class="[notification.read ? 'cp-notification__content-read' : '']" class="cp-notes__body">
                                                    {{ notification.message }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="cp-grid cp-grid--ver">
                        <div class="cp-grid cp-grid--hor cp-grid__item cp-grid__item--fluid cp-grid__item--middle">
                            <div class="cp-grid__item cp-grid__item--middle cp-align-center">
                                {{ 'allCaughtUp' | translate }}
                                <br>{{ 'noCurrentNotifications' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="cp-grid cp-grid--ver">
                <div class="cp-grid cp-grid--hor cp-grid__item cp-grid__item--fluid cp-grid__item--middle">
                    <div class="cp-grid__item cp-grid__item--middle cp-align-center">
                        <div class="loading-spinner col-sm">
                            <div class="cp-spinner cp-spinner--lg cp-spinner--dark"></div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </header-slide-out>
</template>

<script>
    export default {
        components: {},
        name: 'app-notifications',
        props: {
            accessPermissions: Object,
            notificationsData: Object,
        },
        data() {
            return {
                updating: false,
                updatingId: null,
                loaded: false,
                notifications: this.notificationsData.notifications,
            }
        },
        beforeMount() {
            this.performAction(`/notifications`)
                .then(({ data }) => {
                    this.notifications = data
                })
                .catch(() => {
                    this.notifications = []
                })
                .finally(() => {
                    this.loaded = true
                })
        },
        mounted() {
            this.$refs.slideOut.open()
        },
        methods: {
            /**
             * Closes the slide-out
             */
            close() {
                this.$refs.slideOut.close()
                this.$emit('close')
            },
            /**
             * Performs notification action request
             *
             * @param url
             * @returns {object}
             */
            performAction(url) {
                return axios.get(url).then(response => {
                    return Promise.resolve(response)
                })
            },
            /**
             * Trigger notification action
             */
            toggleNotificationAction(notification, action) {
                this.updating = true
                this.updatingId = notification.id
                this.performAction(`/notification-action/${notification.id}/${action}`)
                    .then(({ data }) => {
                        this.notifications = data
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.updating = false
                        this.updatingId = null
                    })
            }
        },
        computed: {
            slideOutData() {
                return {
                    slideOutData: this.notificationsData,
                }
            },
            /**
             * Calculates the total number of new notifications
             *
             * @returns {number}
             */
            newNotificationCount() {
                return _filter(this.notifications, notification => {
                    return !notification.read
                }).length
            },
        },
        watch: {
            /**
             * Emits admin:setNotificationCount to the Event Bus
             */
            newNotificationCount() {
                EventBus.$emit('header:setNotificationCount', this.newNotificationCount)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../sass/variables", "../../sass/mixins";

    .content {
        padding-top: 20px;
    }

    .cp-notes__desc {
        padding-bottom: 5px;
        padding-top: 5px;
    }

    .cp-notes__content {
        padding-left: 10px;
        padding-right: 10px;
    }

    .cp-notes .cp-notes__items .cp-notes__item {
        left: 5px;
        border-left: none;
        margin: 0 5px 0 5px;

        @media (max-width: 1024px) {
            left: 10px;
            margin: 0 10px 0 10px;
        }
    }

    .cp-notification__item-read {
        opacity: .5;
    }
    .cp-notification__content-read {
        text-decoration: line-through;
    }
    .notification_action_button {
        padding-top: 10px;
    }
    .loading-spinner {
        top: 30px;
    }
</style>
