<template>

</template>

<script>
    export default {
        props: {},
        name: 'app-notices',
        data() {
            return {}
        },
        mounted() {

        },
        methods: {

        },
        computed: {

        }
    }
</script>

<style lang="scss" scoped>
    .test {
        position: relative;
    }
</style>
