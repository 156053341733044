<template>
    <header-slide-out :class="'cp-quick-panel'" v-bind="slideOutData" ref="slideOut" @close="close()">
        <div class="search">
            <input type="text" v-model="terms" @keyup="search" ref="terms" class="form-control" :placeholder="'search' | translate"/>

            <transition name="search-animate">
                <div v-if="terms.length <= 1" class="action">
                    <span id="basic-addon1">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="cp-svg-icon">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <rect id="bound" x="0" y="0" width="24" height="24"></rect>
                                <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" id="Path-2" fill="#000000" fill-rule="nonzero" opacity="0.3"></path>
                                <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" id="Path" fill="#000000" fill-rule="nonzero"></path>
                            </g>
                        </svg>
                    </span>
                </div>

                <button v-else @click="clearSearch()" class="action action-button">
                    <i class="icon fa fa-times"></i>
                </button>
            </transition>
        </div>

        <div v-if="filterTypes.length > 1" class="actions">
            <div class="actions-dropdown" ref="filtersDropDown">
                <button @click="toggleFiltersDropDown()" type="button" class="btn btn-brand dropdown-toggle">
                    {{ 'filters' | translate }}
                </button>

                <transition name="actions">
                    <ul v-show="filtersDropDown">
                        <li v-for="(typeName, type) in filters" @click="toggleFilter(type)">
                            <input type="checkbox" :value="type" v-model="filtersVisible">
                            <span></span>
                            <label class="checkbox-label"> {{ typeName }}</label>
                        </li>
                    </ul>
                </transition>
            </div>
        </div>

        <div class="content">
            <div class="cp-quick-search cp-quick-search--inline cp-quick-search--has-result">
                <div class="cp-quick-search__wrapper">
                    <div class="cp-quick-search__result" style="padding-left: 20px">
                        <p v-if="searching">
                            <i class="icon fa fa-spin fa-spinner"></i>
                            {{ 'searching' | translate }}
                        </p>

                        <template v-else>
                            <p v-if="terms.length > 1 && resultsEmpty">
                                {{ 'noResultsFound' | translate }}
                            </p>

                            <template v-if="results" v-for="(type, typeName) in results">
                                <template v-if="type && type.length">
                                    <div class="cp-quick-search__category">
                                        <h4>{{ typeName }}</h4>
                                    </div>

                                    <ul class="cp-search-cards-list">
                                        <li v-for="result in type">
                                            <template v-if="result.resultType === 'users'">
                                                <a :href="result.url">
                                                    <span>
                                                        <div class="cp-search-cards">
                                                            <div class="cp-search-cards__avatar">
                                                                <img v-if="result.pic" alt="photo" :src="result.pic" class="avatar">
                                                                <span v-else class="cp-badge cp-badge--primary cp-badge--xl">
                                                                    {{ result.initials }}
                                                                </span>
                                                            </div>
                                                            <div class="details cp-search-cards__details">
                                                                <div class="cp-search-cards__name">
                                                                    {{ result.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </a>
                                            </template>

                                            <template v-if="result.resultType === 'accounts'">
                                                <a :href="result.url">
                                                    <span>
                                                        <div class="cp-search-cards">
                                                            <div class="cp-search-cards__avatar">
                                                                <img v-if="result.pic" alt="photo" :src="result.pic" class="avatar">
                                                                <span v-else class="cp-badge cp-badge--warning cp-badge--xl">
                                                                    {{ result.initials }}
                                                                </span>
                                                            </div>
                                                            <div class="details cp-search-cards__details">
                                                                <div class="cp-search-cards__name">
                                                                    {{ result.name }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </a>
                                            </template>
                                        </li>
                                    </ul>
                                </template>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </header-slide-out>
</template>

<script>
    import _clone from 'lodash/clone'
    import _debounce from 'lodash/debounce'
    import _each from 'lodash/each'
    import _includes from 'lodash/includes'
    import _isEmpty from 'lodash/isEmpty'
    import _every from 'lodash/every'
    import _groupBy from 'lodash/groupBy'
    import _without from 'lodash/without'

    export default {
        name: 'app-search',
        props: {
            searchFilters: Object,
            searchHeaderData: Object
        },
        data() {
            return {
                terms: '',
                resultsAll: null,
                searching: false,
                filtersDropDown: false,
                filtersVisible: []
            }
        },
        beforeMount() {
            this.filtersVisible = _clone(this.filterTypes)
        },
        mounted() {
            this.$refs.slideOut.open()

            this.$nextTick(() => {
                setTimeout(() => {
                    this.$refs.terms.focus()
                }, 480)
            })
        },
        methods: {
            /**
             * Closes the slide-out
             */
            close() {
                this.$refs.slideOut.close()
                this.$emit('close')
                this.terms = ''
                this.resultsAll = null
            },
            /**
             * Toggles the filters drop down menu
             */
            toggleFiltersDropDown() {
                this.filtersDropDown = !this.filtersDropDown

                if (this.filtersDropDown) {
                    this.$nextTick(() => {
                        const clickOut = event => {
                            if (typeof event.path === 'undefined') {
                                event.path = Tools.getPath(event)
                            }

                            if (!_includes(event.path, this.$refs.filtersDropDown)) {
                                this.filtersDropDown = false

                                window.removeEventListener('click', clickOut)
                            }
                        }

                        window.addEventListener('click', clickOut)
                    })
                }
            },
            /**
             * Toggles the selected filter
             *
             * @param {string} filter
             */
            toggleFilter(filter) {
                if (this.filterVisible(filter)) {
                    this.filtersVisible = _without(this.filtersVisible, filter)
                } else {
                    this.filtersVisible.push(filter)
                }
            },
            /**
             * Checks whether the current filter is visible
             *
             * @param {string} filter
             * @returns {boolean}
             */
            filterVisible(filter) {
                return _includes(this.filtersVisible, filter)
            },
            /**
             * Trigger debouncedSearch if more than 1 character
             */
            search() {
                if (this.terms.length > 1) {
                    this.searching = true
                    this.debouncedSearch()
                } else {
                    this.searching = false
                }
            },
            /**
             * Performs a debounced search
             */
            debouncedSearch: _debounce(function() {
                this.performSearch(`/global-search/${encodeURI(this.terms)}`)
                    .then(({ data }) => {
                        this.resultsAll = data
                    })
                    .catch(() => {})
                    .finally(() => {
                        this.searching = false
                    })
            }, 500),
            /**
             * Clears search
             */
            clearSearch() {
                this.terms = ''
                this.resultsAll = null

                this.$nextTick(() => {
                    this.$refs.terms.focus()
                })
            },
            /**
             * Sets the border colour for member profile pictures
             *
             * @returns {object}
             */
            personStyles() {
                return {
                    borderColor: 'blue'
                }
            },
            /**
             * Performs search request
             *
             * @param url
             * @param config
             * @returns {object}
             */
            performSearch(url, config = {}) {
                const method = config.method || 'get'
                const options = { method, url}
                return axios(options).then(response => {
                    return Promise.resolve(response)
                })
            }
        },
        computed: {
            /**
             * Gets the types of filters available
             *
             * @returns {array}
             */
            filterTypes() {
                const types = []

                _each(this.searchFilters, (type, key) => {
                    types.push(key)
                })

                return types
            },
            filters() {
                return this.searchFilters;
            },
            /**
             * Groups results by category
             *
             * @returns {object}
             */
            results() {
                const grouped = _groupBy(this.resultsAll, 'resultType')
                const results = {}

                _each(grouped, (group, type) => {
                    if (grouped[type] && _includes(this.filtersVisible, type)) {
                        results[type] = group
                    }
                })

                return {
                    users: results.users || null,
                    accounts: results.accounts || null
                }
            },
            /**
             * Returns true if results are empty
             *
             * @returns {boolean}
             */
            resultsEmpty() {
                return _every(this.results, _isEmpty)
            },
            slideOutData() {
                return {
                    slideOutData: this.searchHeaderData,
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../sass/variables", "../../sass/mixins";

    .search {
        position: relative;
        padding: 8px;
        margin-top: 20px;
        &-animate {
            &-enter-active,
            &-leave-active {
                transition: all 240ms;
            }
            &-enter,
            &-leave-to {
                opacity: 0;
                transform: scale(0);
            }
        }

        .action {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            padding: 0;
            text-align: center;
            color: $grey-light;
            background-color: transparent;
            border: none;
            outline: none;

            &.action-button {
                color: $red;
                font-size: 18px;
            }
        }
    }

    .actions {
        position: relative;
        padding: 0;
        background-color: white;
        z-index: 20;

        &-enter-active,
        &-leave-active {
            transform-origin: 0 0;
            transition: all 240ms;
        }

        &-enter,
        &-leave-to{
            opacity: 0;
            transform: scale(0.5);
        }

        > * {
            margin: 4px;
            position: relative;
            display: inline-block;
            vertical-align: top;
        }

        ul {
            cursor: pointer;
            background-color: white;
            list-style: none;
            width: 120px;
            border-radius: $radius;
            padding-inline-start: 10px;
            overflow: hidden;
            z-index: 10;
            @include shadow;

            li {
                cursor: pointer;
                margin-top: 5px;
                margin-bottom: 5px;

                .checkbox-label {
                    padding-left: 5px;
                }

                .box {
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    font-size: 16px;
                    text-align: center;
                    border-radius: 2px;
                    transition: background-color 240ms, border-color 240ms;
                }
            }
        }
    }

    .cp-search-cards-list {
        list-style: none;
        padding-left: 20px;
    }

    .cp-search-cards {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding-bottom: 0.5rem;
        .cp-search-cards__avatar {
            .avatar {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                transition: left $transition;
            }
        }
        .cp-search-cards__details {
            padding-left: 1.5rem;
            padding-bottom: 1rem;
            a.cp-search-cards__name {
                font-size: 1.2rem;
                font-weight: 500;
                color: #33323f;
                text-decoration: none;
                padding-bottom: 0.8rem;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                &:hover {
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    color: #5d78ff;
                }
            }
            .cp-search-cards__desc {
                font-size: 1rem;
                font-weight: 400;
                color: #b2afc6;
                padding: 0.8rem 0;
            }
            .cp-search-cards__info {
                a.cp-search-cards__item {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    padding-bottom: 0.8rem;
                    >i {
                        line-height: 0;
                        font-size: 1.4rem;
                    }
                    .cp-search-cards__tag {
                        font-size: 0.9rem;
                        font-weight: 400;
                        color: #9e9bb1;
                        padding-left: 0.8rem;
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                    }
                    &:hover {
                        .cp-search-cards__tag {
                            -webkit-transition: all 0.3s;
                            transition: all 0.3s;
                            color: #5d78ff;
                        }
                    }
                }
            }
        }
    }

</style>
