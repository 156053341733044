<template>

    <div v-if="showPerPage" class="per-page">
        <button type="button" @click="toggle()" ref="toggle">{{ perPage }}<span class="caret"></span></button>
        <transition name="menu">
            <ul v-if="menu" class="menu">
                <li v-for="option in showOptions" @click="updatePerPage(option)" :class="{ 'active': perPage === option }">{{ option }}</li>
            </ul>
        </transition>
    </div>

</template>

<script>
    import {
        each as _each
    } from 'lodash-es'
    export default {
        name: 'per-page',
        data() {
            return {
                menu: false
            }
        },
        props: [
            'count',
            'list',
            'options'
        ],
        methods: {
            /**
             * Toggles dropdown
             */
            toggle() {
                this.menu = !this.menu
                if (this.menu) {
                    this.$nextTick(() => {
                        document.addEventListener('click', event => {
                            if (typeof event.path === 'undefined') {
                                event.path = Helpers.getPath(event)
                            }
                            if (!_includes(event.path, this.$refs.toggle)) {
                                this.menu = false
                            }
                        }, { once: true })
                    })
                }
            },
            /**
             * Set current page
             * @param count
             */
            updatePerPage(count) {
                this.menu = false
                this.$emit('change', count)
            }
        },
        computed: {
            /**
             * Gets perPage from count prop
             *
             * returns {number}
             */
            perPage() {
                return this.count
            },
            /**
             * Shows perPage if required
             *
             * returns {boolean}
             */
            showPerPage() {
                return this.list.length > this.showOptions[0]
            },
            /**
             * Shows per page options
             *
             * @returns {array}
             */
            showOptions() {
                let options = this.options ? this.options : [10, 20, 50, 100, 250, 500],
                    newOptions = [],
                    count = 0,
                    length = this.list.length
                _each(options, option => {
                    if (option <= length) {
                        newOptions.push(option)
                    } else {
                        return
                    }
                    count++
                })
                if (options[count]) {
                    newOptions.push(options[count])
                }
                return newOptions
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../sass/variables", "../../sass/mixins";

    .per-page {
        display: inline-block;
        position: relative;
        font-size: 16px;
        button {
            @include button-light;
            padding-right: 26px;
            .caret {
                position: absolute;
                top: 50%;
                right: 8px;
                margin-top: -2px;
            }
        }
        .menu {
            position: absolute;
            top: 100%;
            margin-top: 2px;
            cursor: pointer;
            padding-left: 0px;
            z-index: 100;
            @include shadow;
            li {
                list-style: none;
                min-width: 120px;
                margin: 0;
                padding: 6px 12px;
                color: $grey;
                background-color: #fff;
                font-weight: bold;
                transition: color 240ms, background-color 240ms;
                &:hover {
                    color: $grey-dark;
                    background-color: #eee;
                }
                &.active {
                    color: #fff;
                    background-color: $app-color;
                }
            }
            &-enter-active,
            &-leave-active {
                transform-origin: 0 0;
                transition: opacity 120ms, transform 120ms;
            }
            &-enter,
            &-leave-to {
                opacity: 0;
                transform: scale(0.75);
            }
        }
    }
</style>