<!--

Full Modal
<full-modal></full-modal>

This component creates a full-screen modal. Any content may be placed within the
<full-modal></full-modal> tags. You must provide a ref to be able to target it
from the Vue model. Header and footer content may be added either via structured
json passed through as an :option or using slots. Structured will override slots
and tey will be ignored. You can trigger methods automatically when showing or
hiding via the @show and @hide methods or passing through actions via the
buttons and calling using the @action method.

Required:
ref="refName"

Optional:
:options="{
    header: 'Header text string',
    hash: 'hash-name',
    size: 'sm',
    buttons: [
        {
            title: 'Action String', {string} (required)
            color: 'red',           {string} (optional)
            action: 'onAction',     {string} (required)
            left: true              {boolean} (optional)
        },
        {
            title: 'Action String', {string} (required)
            color: 'green',         {string} (optional)
            action: 'onAction'      {string} (required)
        }
    ]
}"

@action="onAction"
Triggers call on Vue model (from button option)

@hide="onHideAction"
Triggers call on Vue model when modal is hidden

@show="onShowAction"
Triggers call on Vue model when modal is shown

Methods:
Show modal
this.$refs.refName.show()

Hide modal
this.$refs.refName.hide()

Slots:
Header
<div slot="header">Header content</div>

Footer
<div slot="footer">Footer content</div>

-->

<template>

    <transition name="full-modal">
<!--        <div v-if="visible" class="modal fade show" id="cp_modal_1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" style="display: block;" aria-modal="true">-->
<!--            <div class="modal-dialog" role="document">-->
<!--                <div class="modal-content">-->
<!--                    <div class="modal-header">-->
<!--                        <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>-->
<!--                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--                        </button>-->
<!--                    </div>-->
<!--                    <div class="modal-body">-->
<!--                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>-->
<!--                    </div>-->
<!--                    <div class="modal-footer">-->
<!--                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>-->
<!--                        <button type="button" class="btn btn-primary">Save changes</button>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div v-if="visible" class="full-modal" :style="{ zIndex: zIndex }">

            <div class="full-modal-inner" :class="size" ref="modalInner">

                <div v-if="header" class="full-modal-header" ref="modalHeader">
                    {{ options.header }}
                    <button type="button" @click="hide()"><span></span></button>
                </div>
                <div v-else-if="headerSlot" class="full-modal-header-alt" ref="modalHeader">
                    <slot name="header"></slot>
                </div>

                <div class="full-modal-content" ref="modalContent">
                    <slot></slot>
                </div>

                <div v-if="hasButtons" class="full-modal-footer" ref="modalFooter">
                    <div v-if="buttons.left.length" class="left">
                        <button v-for="button in buttons.left" type="button" :class="button.color" @click="action(button.action)" :disabled="saving">{{ button.title }}</button>
                    </div>
                    <div v-if="buttons.right.length" class="right">
                        <button v-for="button in buttons.right" type="button" :class="button.color" @click="action(button.action)" :disabled="saving">
                            <template v-if="button.title === 'save' && saving">
                                <i class="fa fa-left fa-pulse fa-spinner"></i> {{ 'saving' }}
                            </template>
                            <template v-else>
                                {{ button.title }}
                            </template>
                        </button>
                    </div>
                </div>
                <div v-else-if="footerSlot" class="full-modal-footer-alt" ref="modalFooter">
                    <slot name="footer"></slot>
                </div>

            </div>

        </div>
    </transition>

</template>

<script>

    import _ from 'lodash' // Required for lodash to work in prod

    export default {
        name: 'full-modal',
        data() {
            return {
                visible: false,
                scroll: 0,
                zIndex: 1110 + (1050 * 10),
                params: {},
                lastHash: ''
            }
        },
        props: [
            'options',
            'saving'
        ],
        mounted() {
            /**
             * Run doResize
             */
            window.addEventListener('resize', this.doResize)
            /**
             * Show & hide on hash change
             */
            if (this.options && this.options.hash) {
                const hash = this.options.hash

                if (this.parseHash(window.location.hash) === hash) {
                    this.lastHash = hash
                    this.show(this.parseParams(window.location.hash))
                }
                window.addEventListener('hashchange', () => {
                    if (this.parseHash(window.location.hash) === hash && this.lastHash !== hash) {
                        this.show(this.parseParams(window.location.hash))
                    }
                    if (!window.location.hash) {
                        this.hide()
                    }
                    this.lastHash = this.parseHash(window.location.hash)
                })
            }

            /**
             * Make 'esc' close modal
             */
            window.addEventListener('keydown', e => {
                if (this.visible && e.keyCode === 27) {
                    this.hide()
                }
            })
        },
        methods: {
            /**
             * Performs an action from a button
             */
            action(data) {
                this.$emit('action', data)
            },
            /**
             * Shows the modal and updates the hash
             */
            show(params = {}) {
                this.visible = true
                this.setParams(params)
                this.$emit('show')
                this.setBody()
                this.resize()
                Vue.modalCount++

            },
            /**
             * Hides the modal and updates the hash
             */
            hide() {
                this.visible = false
                this.$emit('hide')
                this.setBody()
                Vue.modalCount--
                if (this.options && this.options.hash) {
                    const location = window.location.search !== '' ? window.location.search : '.'
                    history.replaceState({}, document.title, location)
                }
            },
            /**
             * Locks the body into position behind the modal
             */
            setBody() {
                const bodyStyle = document.body.style
                if (this.visible) {
                    this.scroll = window.pageYOffset
                    bodyStyle.position = 'fixed'
                    bodyStyle.overflow = 'hidden'
                    bodyStyle.top = '-' + this.scroll + 'px'
                    bodyStyle.right = 0
                    bodyStyle.left = 0
                } else {
                    document.body.removeAttribute('style')
                    window.scroll(0, this.scroll)
                    this.scroll = 0
                }
            },
            /**
             * Checks for header & footer heights & adjusts content positioning
             */
            resize() {
                if (this.visible) {
                    this.$nextTick(() => {
                        if (this.header || this.headerSlot) {
                            const headerHeight = this.$refs.modalHeader.scrollHeight
                            this.$refs.modalContent.style.top = headerHeight + 'px'
                        }
                        if (this.buttons.left.length || this.buttons.right.length || this.footerSlot) {
                            const footerHeight = this.$refs.modalFooter.scrollHeight
                            this.$refs.modalContent.style.bottom = footerHeight + 'px'
                        }
                    })
                    this.contentSize()
                }
            },
            /**
             * Debounces the resize method
             */
            doResize: _.debounce(function() {
                this.resize()
            }, 200),
            /**
             * Updates the content height and position if size option is passed
             */
            contentSize() {
                if (this.size) {
                    this.$nextTick(() => {
                        const windowHeight = window.innerHeight
                        const contentHeight = this.$refs.modalContent ? this.$refs.modalContent.scrollHeight : 0
                        const headerHeight = this.$refs.modalHeader ? this.$refs.modalHeader.scrollHeight : 0
                        const footerHeight = this.$refs.modalFooter ? this.$refs.modalFooter.scrollHeight : 0
                        if (contentHeight + headerHeight + footerHeight <= windowHeight) {
                            this.$refs.modalContent.classList.add('resized')
                            const newHeight = (this.$refs.modalContent.scrollHeight + headerHeight + footerHeight)
                            this.$refs.modalInner.style.height = newHeight + 'px'

                            if (windowHeight > 900) {
                                this.$refs.modalInner.style.top = '30%'
                            } else {
                                this.$refs.modalInner.style.top = '50%'
                            }

                            this.$refs.modalInner.style.marginTop = '-' + (newHeight /  2) + 'px'
                            this.$refs.modalContent.style.bottom = 'auto'
                        } else {
                            this.$refs.modalContent.classList.remove('resized')
                            this.$refs.modalInner.style.height = null
                            this.$refs.modalInner.style.top = null
                            this.$refs.modalInner.style.marginTop = null
                            this.$refs.modalContent.style.bottom = 0
                        }
                    })
                }
            },
            /**
             * Gets the hash from a URL fragment
             * @param {string} fragment
             * @return {string}
             */
            parseHash(fragment) {
                const segments = fragment.split('?')
                return segments[0].replace('#', '')
            },
            /**
             * Gets the hash parameters from a URL fragment
             * @param {string} fragment
             * @return {object}
             */
            parseParams(fragment) {
                const segments = fragment.split('?')
                if (!segments[1]) {
                    return {}
                }
                const pairs = segments[1].split(';')
                return _.fromPairs(_.map(pairs, pair => pair.split(':')))
            },
            /**
             * Overwrites the hash params and updates the URL fragment
             * @param {object} params
             */
            setParams(params) {
                this.params = params
                if (this.options && this.options.hash) {
                    let hash = this.options.hash
                    hash += '?'
                    _.each(this.params, (v, k) => hash += k + ':' + v + ';')
                    hash = hash.slice(0, -1)
                    this.lastHash = this.parseHash(hash)
                    window.location.hash = hash
                }
            }
        },
        computed: {
            size() {
                return this.options && this.options.size ? 'full-modal-inner-' + this.options.size : null
            },
            /**
             * Does the modal have a header
             * @returns {string} header text
             */
            header() {
                return !!this.options && !!this.options.header
            },
            /**
             * Header Slot
             * @returns {boolean}
             */
            headerSlot() {
                return !!this.$slots.header
            },
            /**
             * Does the modal have buttons
             * @returns {boolean}
             */
            hasButtons() {
                return !!this.options && !!this.options.buttons
            },
            /**
             * Prepares button content
             * @returns {object}
             */
            buttons() {
                let buttons = {
                    left: [],
                    right: []
                }
                if (this.options && this.options.buttons) {
                    _.each(this.options.buttons, button => {
                        if (button.left) {
                            buttons.left.push(button)
                        } else {
                            buttons.right.push(button)
                        }
                    })
                }
                return buttons
            },
            /**
             * Footer Slot
             * @returns {boolean}
             */
            footerSlot() {
                return !!this.$slots.footer
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import "../../sass/variables", "../../sass/mixins";

    .full-modal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: mix(transparent, black);

        .full-modal-inner {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: white;
            @include shadow;
            &-sm {
                margin: 16px;
                @media (min-width: $screen-sm) {
                    max-width: 320px;
                    left: 50%;
                    margin-left: -160px;

                    .full-modal-content {
                        &.resized {
                            position: relative;
                            top: auto;
                            right: auto;
                            bottom: auto;
                            left: auto;
                        }
                    }

                }
            }
        }

        .full-modal-header {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            padding: 16px 64px 16px 16px;
            font-size: 20px;
            line-height: 1.25;
            background-color: $pale;
            box-shadow: $box-shadow;

            &-alt {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                background-color: white;
            }

            button {
                position: absolute;
                top: 50%;
                right: 8px;
                margin-top: -24px;
                width: 48px;
                height: 48px;
                padding: 0;
                background: transparent;
                border: none;
                transition: all 240ms;
                outline: none;
                cursor: pointer;
                span {
                    position: absolute;
                    display: block;
                    top: 22px;
                    left: 9px;
                    &::before,
                    &::after {
                        position: absolute;
                        content: '';
                        height: 2px;
                        width: 32px;
                        background-color: mix(white, black);
                    }
                    &::before {
                        transform: rotate(45deg);
                    }
                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }

        }

        .full-modal-footer {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 4px;
            background-color: $pale;
            box-shadow: $box-shadow;
            @include clearfix;

            &-alt {
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: white;
            }

            button {
                margin: 4px;
                @include button(14px, 6px 12px);
                @include button-color(white, $grey-light);
                &.red {
                    @include button-color(white, $red);
                }
                &.green {
                    @include button-color(white, $green);
                }
                &.blue {
                    @include button-color(white, $blue);
                }

                &:disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }

                @media (min-width: $screen-sm) {
                    @include button;
                }

            }

            .left {
                float: left;
            }
            .right {
                float: right;
            }

        }

        .full-modal-content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 16px;
            overflow-x: auto;
        }

        // Animations
        &-enter-active,
        &-leave-active {
            transition: opacity $transition;
            .full-modal-inner {
                transition: transform $transition;
            }
        }
        &-enter {
            opacity: 0;
            .full-modal-inner {
                transform: scale(1.25);
            }
        }
        &-leave-to {
            opacity: 0;
            .full-modal-inner {
                transform: scale(0.75);
            }
        }

    }
</style>
