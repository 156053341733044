<template>
    <div class="cp-header-menu-wrapper" id="cp-header_menu_wrapper">
        <button class="cp-header-menu-wrapper-close" id="cp-header_menu_mobile_close_btn">
            <i class="la la-close"></i>
        </button>

        <div id="cp-header_menu" class="cp-header-menu cp-header-menu-mobile  cp-header-menu--layout-default ">
            <ul class="cp-menu__nav menu-navigation">
                <li v-for="menuLink in menuLinks" class="cp-menu__item cp-menu__item--submenu cp-menu__item--rel">
                    <a v-if="menuLink.name" :href="menuLink.link" class="cp-menu__link ">
                        <i :class="menuLink.icon" class="cp-menu__link-icon"></i>
                        <span class="cp-menu__link-text">
                            {{menuLink.name}}
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            data: Object
        },
        name: 'app-menu',
        data() {
            return {
                contentExpanded: true,
                currentUser: this.data.currentUser,
                menuLinks: this.data.appLinks,
            }
        },
        mounted() {

        },
        methods: {

        },
        computed: {

        }
    }
</script>

<style lang="scss" scoped>
    @media (min-width: 1025px) {
        .cp-header-menu-wrapper {
            height: 50px;
        }
    }
    @media (max-width: 1024px) {
        .cp-header-menu-wrapper {
            background-color: #1e1e2d;
            .cp-header-menu-wrapper-close {
                background-color: #366cf3;
            }
        }
        .cp-header-menu-mobile {
            .cp-menu__nav {
                margin: 0;
                list-style: none;
                padding: 30px 0 30px 0;
                .cp-menu__scroll {
                    position: relative;
                    overflow: hidden;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                }
                .cp-menu__subnav {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                    padding: 0;
                    margin: 0;
                    list-style: none !important;
                }
                .cp-menu__inner {
                    display: none;
                    float: none;
                    margin: 0;
                    padding: 0;
                    width: auto !important;
                    .cp-menu__content {
                        padding: 0;
                        margin: 0;
                        >.cp-menu__item {
                            padding: 0;
                            margin: 0;
                            list-style: none;
                        }
                        >.cp-menu__item.cp-menu--no-heading {
                            >.cp-menu__inner {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                >li {
                                    >.cp-menu__link {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .cp-menu__submenu {
                    display: none;
                    float: none;
                    margin: 0;
                    padding: 0;
                    width: auto !important;
                    .cp-menu__content {
                        padding: 0;
                        margin: 0;
                        >.cp-menu__item {
                            padding: 0;
                            margin: 0;
                            list-style: none;
                        }
                        >.cp-menu__item.cp-menu--no-heading {
                            >.cp-menu__inner {
                                display: -webkit-box;
                                display: -ms-flexbox;
                                display: flex;
                                >li {
                                    >.cp-menu__link {
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                .cp-menu__item {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                    flex-grow: 1;
                    float: none;
                    padding: 0;
                    >.cp-menu__heading {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                        flex-grow: 1;
                        -webkit-box-align: stretch;
                        -ms-flex-align: stretch;
                        align-items: stretch;
                        margin: 0;
                        padding: 0;
                        text-decoration: none;
                        position: relative;
                        outline: none;
                        &:hover {
                            text-decoration: none;
                            cursor: pointer;
                        }
                        .cp-menu__link-text {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-flex: 1;
                            -ms-flex-positive: 1;
                            flex-grow: 1;
                            padding: 0;
                        }
                        .cp-menu__link-badge {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            white-space: nowrap;
                        }
                        .cp-menu__link-icon {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            line-height: 0;
                        }
                        .cp-menu__link-bullet {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            line-height: 0;
                        }
                        .cp-menu__ver-arrow {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-pack: end;
                            -ms-flex-pack: end;
                            justify-content: flex-end;
                            line-height: 0;
                            &:before {
                                -webkit-transform: translate3d(0, 0, 0);
                            }
                        }
                        .cp-menu__hor-arrow {
                            display: none;
                        }
                    }
                    >.cp-menu__link {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                        flex-grow: 1;
                        -webkit-box-align: stretch;
                        -ms-flex-align: stretch;
                        align-items: stretch;
                        margin: 0;
                        padding: 0;
                        text-decoration: none;
                        position: relative;
                        outline: none;
                        &:hover {
                            text-decoration: none;
                            cursor: pointer;
                        }
                        .cp-menu__link-text {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-flex: 1;
                            -ms-flex-positive: 1;
                            flex-grow: 1;
                            padding: 0;
                        }
                        .cp-menu__link-badge {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            white-space: nowrap;
                        }
                        .cp-menu__link-icon {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            line-height: 0;
                        }
                        .cp-menu__link-bullet {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            line-height: 0;
                        }
                        .cp-menu__ver-arrow {
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            align-items: center;
                            -webkit-box-pack: end;
                            -ms-flex-pack: end;
                            justify-content: flex-end;
                            line-height: 0;
                            &:before {
                                -webkit-transform: translate3d(0, 0, 0);
                            }
                        }
                        .cp-menu__hor-arrow {
                            display: none;
                        }
                    }
                    .cp-menu__submenu {
                        .cp-menu__item--parent {
                            display: none;
                        }
                    }
                }
                .cp-menu__item.cp-menu__item--open {
                    >.cp-menu__heading {
                        >.cp-menu__ver-arrow {
                            &:before {
                                -webkit-transform: rotateZ(90deg);
                                transform: rotateZ(90deg);
                            }
                        }
                    }
                    >.cp-menu__link {
                        >.cp-menu__ver-arrow {
                            &:before {
                                -webkit-transform: rotateZ(90deg);
                                transform: rotateZ(90deg);
                            }
                        }
                    }
                    >.cp-menu__submenu {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                        flex-grow: 1;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                        flex-direction: column;
                    }
                    >.cp-menu__inner {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-flex: 1;
                        -ms-flex-positive: 1;
                        flex-grow: 1;
                        -webkit-box-orient: vertical;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                        flex-direction: column;
                    }
                }
                .cp-menu__section {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    padding: 0 27px;
                    .cp-menu__section-text {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        margin: 0;
                        padding: 0;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                    }
                    .cp-menu__section-icon {
                        display: none;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                    }
                }
                .cp-menu__section.cp-menu__section--first {
                    margin-top: 0 !important;
                }
                .cp-menu__separator {
                    height: 0;
                    overflow: hidden;
                }
                .cp-menu__separator.cp-menu__separator--marginles {
                    margin: 0;
                }
                >.cp-menu__item {
                    position: relative;
                    margin: 0;
                    >.cp-menu__heading {
                        cursor: pointer;
                        height: 44px;
                        .cp-menu__link-text {
                            font-weight: 400;
                            font-size: 1.02rem;
                            text-transform: initial;
                            color: #5e6383;
                        }
                        .cp-menu__link-icon {
                            -webkit-box-flex: 0;
                            -ms-flex: 0 0 35px;
                            flex: 0 0 35px;
                            font-size: 1.4rem;
                            color: #a8aabb;
                            svg {
                                margin-left: -2px;
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                        fill: #a8aabb;
                                    }
                                }
                                &:hover {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                        }
                                    }
                                }
                            }
                        }
                        .cp-menu__link-bullet {
                            vertical-align: middle;
                            text-align: left;
                            -webkit-box-flex: 0;
                            -ms-flex: 0 0 15px;
                            flex: 0 0 15px;
                            >span {
                                vertical-align: middle;
                                display: inline-block;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                            >span {
                                width: 4px;
                                height: 4px;
                                border-radius: 100%;
                                background-color: #a8aabb;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                            >span {
                                width: 5px;
                                height: 1px;
                                background-color: #a8aabb;
                            }
                        }
                        .cp-menu__link-badge {
                            padding: 0px 0px 0px 5px;
                            text-align: right;
                        }
                        .cp-menu__ver-arrow {
                            -webkit-box-flex: 0;
                            -ms-flex: 0 0 20px;
                            flex: 0 0 20px;
                            font-size: 0.7rem;
                            color: #a8aabb;
                            &:before {
                                display: inline-block;
                                -webkit-transition: all 0.3s ease;
                                transition: all 0.3s ease;
                            }
                        }
                        .cp-menu__item-here {
                            display: none;
                        }
                    }
                    >.cp-menu__link {
                        cursor: pointer;
                        height: 44px;
                        padding: 9px 30px;
                        .cp-menu__link-text {
                            font-weight: 400;
                            font-size: 1.02rem;
                            text-transform: initial;
                            color: #5e6383;
                        }
                        .cp-menu__link-icon {
                            -webkit-box-flex: 0;
                            -ms-flex: 0 0 35px;
                            flex: 0 0 35px;
                            font-size: 1.4rem;
                            color: #a8aabb;
                            svg {
                                margin-left: -2px;
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                        fill: #a8aabb;
                                    }
                                }
                                &:hover {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                        }
                                    }
                                }
                            }
                        }
                        .cp-menu__link-bullet {
                            vertical-align: middle;
                            text-align: left;
                            -webkit-box-flex: 0;
                            -ms-flex: 0 0 15px;
                            flex: 0 0 15px;
                            >span {
                                vertical-align: middle;
                                display: inline-block;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                            >span {
                                width: 4px;
                                height: 4px;
                                border-radius: 100%;
                                background-color: #a8aabb;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                            >span {
                                width: 5px;
                                height: 1px;
                                background-color: #a8aabb;
                            }
                        }
                        .cp-menu__link-badge {
                            padding: 0px 0px 0px 5px;
                            text-align: right;
                        }
                        .cp-menu__ver-arrow {
                            -webkit-box-flex: 0;
                            -ms-flex: 0 0 20px;
                            flex: 0 0 20px;
                            font-size: 0.7rem;
                            color: #a8aabb;
                            &:before {
                                display: inline-block;
                                -webkit-transition: all 0.3s ease;
                                transition: all 0.3s ease;
                            }
                        }
                        .cp-menu__item-here {
                            display: none;
                        }
                    }
                    .cp-menu__submenu {
                        .cp-menu__subnav {
                            margin: 0;
                        }
                        .cp-menu__item {
                            margin: 0;
                            >.cp-menu__heading {
                                cursor: pointer;
                                height: 40px;
                                .cp-menu__link-text {
                                    font-weight: 400;
                                    font-size: 1rem;
                                    text-transform: initial;
                                    color: #6b6f8d;
                                }
                                .cp-menu__link-icon {
                                    -webkit-box-flex: 0;
                                    -ms-flex: 0 0 35px;
                                    flex: 0 0 35px;
                                    font-size: 1.35rem;
                                    color: #a8aabb;
                                    svg {
                                        margin-left: -2px;
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                                fill: #a8aabb;
                                            }
                                        }
                                        &:hover {
                                            g {
                                                [fill] {
                                                    -webkit-transition: fill 0.3s ease;
                                                    transition: fill 0.3s ease;
                                                }
                                            }
                                        }
                                    }
                                }
                                .cp-menu__link-bullet {
                                    vertical-align: middle;
                                    text-align: left;
                                    -webkit-box-flex: 0;
                                    -ms-flex: 0 0 20px;
                                    flex: 0 0 20px;
                                    >span {
                                        vertical-align: middle;
                                        display: inline-block;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                    >span {
                                        width: 4px;
                                        height: 4px;
                                        border-radius: 100%;
                                        background-color: #a8aabb;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                    >span {
                                        width: 5px;
                                        height: 1px;
                                        background-color: #a8aabb;
                                    }
                                }
                                .cp-menu__link-badge {
                                    padding: 0px 0px 0px 5px;
                                }
                                .cp-menu__ver-arrow {
                                    -webkit-box-flex: 0;
                                    -ms-flex: 0 0 20px;
                                    flex: 0 0 20px;
                                    font-size: 0.7rem;
                                    color: #a8aabb;
                                    &:before {
                                        display: inline-block;
                                        -webkit-transition: all 0.3s ease;
                                        transition: all 0.3s ease;
                                    }
                                }
                                .cp-menu__item-here {
                                    display: none;
                                }
                            }
                            >.cp-menu__link {
                                cursor: pointer;
                                height: 40px;
                                .cp-menu__link-text {
                                    font-weight: 400;
                                    font-size: 1rem;
                                    text-transform: initial;
                                    color: #6b6f8d;
                                }
                                .cp-menu__link-icon {
                                    -webkit-box-flex: 0;
                                    -ms-flex: 0 0 35px;
                                    flex: 0 0 35px;
                                    font-size: 1.35rem;
                                    color: #a8aabb;
                                    svg {
                                        margin-left: -2px;
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                                fill: #a8aabb;
                                            }
                                        }
                                        &:hover {
                                            g {
                                                [fill] {
                                                    -webkit-transition: fill 0.3s ease;
                                                    transition: fill 0.3s ease;
                                                }
                                            }
                                        }
                                    }
                                }
                                .cp-menu__link-bullet {
                                    vertical-align: middle;
                                    text-align: left;
                                    -webkit-box-flex: 0;
                                    -ms-flex: 0 0 20px;
                                    flex: 0 0 20px;
                                    >span {
                                        vertical-align: middle;
                                        display: inline-block;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                    >span {
                                        width: 4px;
                                        height: 4px;
                                        border-radius: 100%;
                                        background-color: #a8aabb;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                    >span {
                                        width: 5px;
                                        height: 1px;
                                        background-color: #a8aabb;
                                    }
                                }
                                .cp-menu__link-badge {
                                    padding: 0px 0px 0px 5px;
                                }
                                .cp-menu__ver-arrow {
                                    -webkit-box-flex: 0;
                                    -ms-flex: 0 0 20px;
                                    flex: 0 0 20px;
                                    font-size: 0.7rem;
                                    color: #a8aabb;
                                    &:before {
                                        display: inline-block;
                                        -webkit-transition: all 0.3s ease;
                                        transition: all 0.3s ease;
                                    }
                                }
                                .cp-menu__item-here {
                                    display: none;
                                }
                            }
                            &:not(.cp-menu__item--parent) {
                                &:not(.cp-menu__item--open) {
                                    &:not(.cp-menu__item--here) {
                                        &:not(.cp-menu__item--active) {
                                            &:hover {
                                                -webkit-transition: background-color 0.3s;
                                                transition: background-color 0.3s;
                                                >.cp-menu__heading {
                                                    background-color: #1b1b28;
                                                    .cp-menu__link-text {
                                                        color: #5d78ff;
                                                    }
                                                    .cp-menu__link-icon {
                                                        color: #5d78ff;
                                                        svg {
                                                            g {
                                                                [fill] {
                                                                    -webkit-transition: fill 0.3s ease;
                                                                    transition: fill 0.3s ease;
                                                                    fill: #5d78ff;
                                                                }
                                                            }
                                                            &:hover {
                                                                g {
                                                                    [fill] {
                                                                        -webkit-transition: fill 0.3s ease;
                                                                        transition: fill 0.3s ease;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                        >span {
                                                            background-color: #5d78ff;
                                                        }
                                                    }
                                                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                        >span {
                                                            background-color: #5d78ff;
                                                        }
                                                    }
                                                    .cp-menu__ver-arrow {
                                                        color: #5d78ff;
                                                    }
                                                }
                                                >.cp-menu__link {
                                                    background-color: #1b1b28;
                                                    .cp-menu__link-text {
                                                        color: #5d78ff;
                                                    }
                                                    .cp-menu__link-icon {
                                                        color: #5d78ff;
                                                        svg {
                                                            g {
                                                                [fill] {
                                                                    -webkit-transition: fill 0.3s ease;
                                                                    transition: fill 0.3s ease;
                                                                    fill: #5d78ff;
                                                                }
                                                            }
                                                            &:hover {
                                                                g {
                                                                    [fill] {
                                                                        -webkit-transition: fill 0.3s ease;
                                                                        transition: fill 0.3s ease;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                    .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                        >span {
                                                            background-color: #5d78ff;
                                                        }
                                                    }
                                                    .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                        >span {
                                                            background-color: #5d78ff;
                                                        }
                                                    }
                                                    .cp-menu__ver-arrow {
                                                        color: #5d78ff;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .cp-menu__item.cp-menu__item--open {
                            >.cp-menu__heading {
                                .cp-menu__ver-arrow {
                                    &:before {
                                        -webkit-transition: all 0.3s ease;
                                        transition: all 0.3s ease;
                                    }
                                    color: #5d78ff;
                                }
                                background-color: #1b1b28;
                                .cp-menu__link-text {
                                    color: #5d78ff;
                                }
                                .cp-menu__link-icon {
                                    color: #5d78ff;
                                    svg {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                                fill: #5d78ff;
                                            }
                                        }
                                        &:hover {
                                            g {
                                                [fill] {
                                                    -webkit-transition: fill 0.3s ease;
                                                    transition: fill 0.3s ease;
                                                }
                                            }
                                        }
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                            }
                            >.cp-menu__link {
                                .cp-menu__ver-arrow {
                                    &:before {
                                        -webkit-transition: all 0.3s ease;
                                        transition: all 0.3s ease;
                                    }
                                    color: #5d78ff;
                                }
                                background-color: #1b1b28;
                                .cp-menu__link-text {
                                    color: #5d78ff;
                                }
                                .cp-menu__link-icon {
                                    color: #5d78ff;
                                    svg {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                                fill: #5d78ff;
                                            }
                                        }
                                        &:hover {
                                            g {
                                                [fill] {
                                                    -webkit-transition: fill 0.3s ease;
                                                    transition: fill 0.3s ease;
                                                }
                                            }
                                        }
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                            }
                            -webkit-transition: background-color 0.3s;
                            transition: background-color 0.3s;
                        }
                        .cp-menu__section {
                            margin: 20px 0 0 0;
                            height: 40px;
                            .cp-menu__section-text {
                                font-size: 0.8rem;
                                font-weight: 300;
                                text-transform: uppercase;
                                letter-spacing: 0.3px;
                                color: #a4a7bd;
                            }
                            .cp-menu__section-icon {
                                color: #aaacc2;
                            }
                        }
                        .cp-menu__separator {
                            margin: 15px 0;
                            border-bottom: 1px solid rgba(0, 0, 0, 0.07);
                            .cp-menu__separator--marginles {
                                margin: 0;
                            }
                        }
                        .cp-menu__item.cp-menu__item--here {
                            -webkit-transition: background-color 0.3s;
                            transition: background-color 0.3s;
                            >.cp-menu__heading {
                                background-color: #1b1b28;
                                .cp-menu__link-icon {
                                    svg {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                        &:hover {
                                            g {
                                                [fill] {
                                                    -webkit-transition: fill 0.3s ease;
                                                    transition: fill 0.3s ease;
                                                }
                                            }
                                        }
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                            }
                            >.cp-menu__link {
                                background-color: #1b1b28;
                                .cp-menu__link-icon {
                                    svg {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                            }
                                        }
                                        &:hover {
                                            g {
                                                [fill] {
                                                    -webkit-transition: fill 0.3s ease;
                                                    transition: fill 0.3s ease;
                                                }
                                            }
                                        }
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                            }
                        }
                        .cp-menu__item.cp-menu__item--active {
                            -webkit-transition: background-color 0.3s;
                            transition: background-color 0.3s;
                            >.cp-menu__heading {
                                background-color: #1b1b28;
                                .cp-menu__link-text {
                                    color: #5d78ff;
                                }
                                .cp-menu__link-icon {
                                    color: #5d78ff;
                                    svg {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                                fill: #5d78ff;
                                            }
                                        }
                                        &:hover {
                                            g {
                                                [fill] {
                                                    -webkit-transition: fill 0.3s ease;
                                                    transition: fill 0.3s ease;
                                                }
                                            }
                                        }
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                                .cp-menu__ver-arrow {
                                    color: #5d78ff;
                                }
                            }
                            >.cp-menu__link {
                                background-color: #1b1b28;
                                .cp-menu__link-text {
                                    color: #5d78ff;
                                }
                                .cp-menu__link-icon {
                                    color: #5d78ff;
                                    svg {
                                        g {
                                            [fill] {
                                                -webkit-transition: fill 0.3s ease;
                                                transition: fill 0.3s ease;
                                                fill: #5d78ff;
                                            }
                                        }
                                        &:hover {
                                            g {
                                                [fill] {
                                                    -webkit-transition: fill 0.3s ease;
                                                    transition: fill 0.3s ease;
                                                }
                                            }
                                        }
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                                .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                    >span {
                                        background-color: #5d78ff;
                                    }
                                }
                                .cp-menu__ver-arrow {
                                    color: #5d78ff;
                                }
                            }
                        }
                    }
                    >.cp-menu__submenu {
                        .cp-menu__subnav {
                            .cp-menu__content {
                                .cp-menu__heading {
                                    padding: 0 30px;
                                    padding-left: 45px;
                                }
                                .cp-menu__inner {
                                    padding: 0;
                                    margin: 0;
                                    .cp-menu__link {
                                        padding: 0 30px;
                                        padding-left: 60px;
                                    }
                                }
                            }
                            >.cp-menu__item {
                                >.cp-menu__link {
                                    padding: 0 30px;
                                    padding-left: 45px;
                                }
                                >.cp-menu__submenu {
                                    padding: 0;
                                    padding: 0;
                                    .cp-menu__subnav {
                                        padding: 0;
                                        >.cp-menu__item {
                                            >.cp-menu__link {
                                                padding: 0 30px;
                                                padding-left: 60px;
                                            }
                                            >.cp-menu__submenu {
                                                padding: 0;
                                                padding: 0;
                                                .cp-menu__subnav {
                                                    >.cp-menu__item {
                                                        >.cp-menu__link {
                                                            padding: 0 30px;
                                                            padding-left: 75px;
                                                        }
                                                    }
                                                    >.cp-menu__section {
                                                        padding-left: 75px;
                                                    }
                                                }
                                            }
                                        }
                                        >.cp-menu__section {
                                            padding-left: 60px;
                                        }
                                    }
                                }
                            }
                            >.cp-menu__section {
                                padding-left: 45px;
                            }
                        }
                    }
                    &:not(.cp-menu__item--parent) {
                        &:not(.cp-menu__item--open) {
                            &:not(.cp-menu__item--here) {
                                &:not(.cp-menu__item--active) {
                                    &:hover {
                                        -webkit-transition: background-color 0.3s;
                                        transition: background-color 0.3s;
                                        >.cp-menu__heading {
                                            background-color: #1b1b28;
                                            .cp-menu__link-text {
                                                color: #5d78ff;
                                            }
                                            .cp-menu__link-icon {
                                                color: #5d78ff;
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                            fill: #5d78ff;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__ver-arrow {
                                                color: #5d78ff;
                                            }
                                        }
                                        >.cp-menu__link {
                                            background-color: #1b1b28;
                                            .cp-menu__link-text {
                                                color: #5d78ff;
                                            }
                                            .cp-menu__link-icon {
                                                color: #5d78ff;
                                                svg {
                                                    g {
                                                        [fill] {
                                                            -webkit-transition: fill 0.3s ease;
                                                            transition: fill 0.3s ease;
                                                            fill: #5d78ff;
                                                        }
                                                    }
                                                    &:hover {
                                                        g {
                                                            [fill] {
                                                                -webkit-transition: fill 0.3s ease;
                                                                transition: fill 0.3s ease;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__link-bullet.cp-menu__link-bullet--line {
                                                >span {
                                                    background-color: #5d78ff;
                                                }
                                            }
                                            .cp-menu__ver-arrow {
                                                color: #5d78ff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    >.cp-menu__arrow {
                        color: #ffffff;
                    }
                }
                >.cp-menu__item.cp-menu__item--open {
                    >.cp-menu__heading {
                        .cp-menu__ver-arrow {
                            &:before {
                                -webkit-transition: all 0.3s ease;
                                transition: all 0.3s ease;
                            }
                            color: #5d78ff;
                        }
                        background-color: #1b1b28;
                        .cp-menu__link-text {
                            color: #5d78ff;
                        }
                        .cp-menu__link-icon {
                            color: #5d78ff;
                            svg {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                        fill: #5d78ff;
                                    }
                                }
                                &:hover {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                        }
                                    }
                                }
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                    }
                    >.cp-menu__link {
                        .cp-menu__ver-arrow {
                            &:before {
                                -webkit-transition: all 0.3s ease;
                                transition: all 0.3s ease;
                            }
                            color: #5d78ff;
                        }
                        background-color: #1b1b28;
                        .cp-menu__link-text {
                            color: #5d78ff;
                        }
                        .cp-menu__link-icon {
                            color: #5d78ff;
                            svg {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                        fill: #5d78ff;
                                    }
                                }
                                &:hover {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                        }
                                    }
                                }
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                    }
                    -webkit-transition: background-color 0.3s;
                    transition: background-color 0.3s;
                }
                >.cp-menu__section {
                    margin: 20px 0 0 0;
                    height: 40px;
                    .cp-menu__section-text {
                        font-size: 0.83rem;
                        font-weight: 500;
                        text-transform: uppercase;
                        letter-spacing: 0.3px;
                        color: #a4a7bd;
                    }
                    .cp-menu__section-icon {
                        color: #aaacc2;
                    }
                }
                >.cp-menu__separator {
                    margin: 15px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
                    .cp-menu__separator--marginles {
                        margin: 0;
                    }
                }
                >.cp-menu__item.cp-menu__item--here {
                    -webkit-transition: background-color 0.3s;
                    transition: background-color 0.3s;
                    >.cp-menu__heading {
                        background-color: #1b1b28;
                        .cp-menu__link-text {
                            color: #5d78ff;
                        }
                        .cp-menu__link-icon {
                            color: #5d78ff;
                            svg {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                        fill: #5d78ff;
                                    }
                                }
                                &:hover {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                        }
                                    }
                                }
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__ver-arrow {
                            color: #5d78ff;
                        }
                    }
                    >.cp-menu__link {
                        background-color: #1b1b28;
                        .cp-menu__link-text {
                            color: #5d78ff;
                        }
                        .cp-menu__link-icon {
                            color: #5d78ff;
                            svg {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                        fill: #5d78ff;
                                    }
                                }
                                &:hover {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                        }
                                    }
                                }
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__ver-arrow {
                            color: #5d78ff;
                        }
                    }
                }
                >.cp-menu__item.cp-menu__item--active {
                    -webkit-transition: background-color 0.3s;
                    transition: background-color 0.3s;
                    >.cp-menu__heading {
                        background-color: #1b1b28;
                        .cp-menu__link-text {
                            color: #5d78ff;
                        }
                        .cp-menu__link-icon {
                            color: #5d78ff;
                            svg {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                        fill: #5d78ff;
                                    }
                                }
                                &:hover {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                        }
                                    }
                                }
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__ver-arrow {
                            color: #5d78ff;
                        }
                    }
                    >.cp-menu__link {
                        background-color: #1b1b28;
                        .cp-menu__link-text {
                            color: #5d78ff;
                        }
                        .cp-menu__link-icon {
                            color: #5d78ff;
                            svg {
                                g {
                                    [fill] {
                                        -webkit-transition: fill 0.3s ease;
                                        transition: fill 0.3s ease;
                                        fill: #5d78ff;
                                    }
                                }
                                &:hover {
                                    g {
                                        [fill] {
                                            -webkit-transition: fill 0.3s ease;
                                            transition: fill 0.3s ease;
                                        }
                                    }
                                }
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--dot {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__link-bullet.cp-menu__link-bullet--line {
                            >span {
                                background-color: #5d78ff;
                            }
                        }
                        .cp-menu__ver-arrow {
                            color: #5d78ff;
                        }
                    }
                }
            }
            background-color: #1e1e2d;
        }
    }
    .cp-header-mobile {
        position: absolute;
        top: 0;
        width: 50px;
        margin: 0;
        padding: 0;
        padding-bottom: 10px;
    }

    .menu-navigation {
        padding-top: 30px;
    }

</style>
