import AlertBox from './AlertBox.vue'
import Confirm from './Confirm.vue'
import MultiSelect from './MultiSelect.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import ActivitySpinner from './ActivitySpinner.vue'
import PageTable from './PageTable.vue'
import PeopleSearch from "./PeopleSearch";
import FullModal from "./FullModal";

Vue.component('alert-box', AlertBox)
Vue.component('full-modal', FullModal)
Vue.component('confirm', Confirm)
Vue.component('multi-select', MultiSelect)
Vue.component('loading-spinner', LoadingSpinner)
Vue.component('activity-spinner', ActivitySpinner)
Vue.component('page-table', PageTable)
Vue.component('people-search', PeopleSearch)

