<template>
    <transition name="slide-out">
        <div v-if="visible" class="slide-out">
            <header>
                <div class="ct-search-panel__head">
                    <h3 class="ct-demo-panel__title">{{ title }}</h3>
                    <a @click="confirmClose()" href="#" class="cp-quick-panel__close" id="cp-quick_panel_close_btn"><i class="flaticon2-delete"></i></a>
                </div>
            </header>

            <main>
                <slot/>
            </main>

            <footer>
                <slot name="footer"/>
            </footer>
        </div>
    </transition>
</template>

<script>
    export default {
        props: {
            slideOutData: Object
        },
        name: 'header-slide-out',
        data() {
            return {
                visible: false,
                title: this.slideOutData.title,
                headerImage: this.slideOutData.backgroundImage
            }
        },
        methods: {
            /**
             * Opens the slide out
             */
            open() {
                this.visible = true
            },
            /**
             * Closes the slide out
             */
            close() {
                this.visible = false
            },
            /**
             * Waits for confirmation before closing
             */
            confirmClose() {
                this.$emit('close', close => {
                    if (close) {
                        this.close()
                    }
                })
            }
        },
        computed: {
            headerStyles () {
                return {
                    'background-image': 'url(' + this.headerImage + ')'
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../sass/variables", "../../sass/mixins";

    .slide-out {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        color: $grey-dark;
        background-color: white;
        font-size: 15px;
        z-index: 1050;
        overflow-x: auto;
        transition: right 240ms;
        @include shadow;

        &-enter-active,
        &-leave-active {
            transition: all 480ms;
        }

        &-enter,
        &-leave-to {
            transform: translateX(100%);
            opacity: 0;
        }

        @media (min-width: $screen-sm) {
            width: 480px;
        }
    }

    header {
        position: relative;
        flex: 0;
        //padding: 12px 48px 12px 12px;
        font-size: 20px;
        line-height: 24px;
        background-color: $background;

        @media (max-width: 1024px) {
            height: 50px;
        }

        .cp-global-search-bar {
            padding-left: 10px;
            padding-right: 10px;
        }
        .ct-search-panel__head {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            background-position: center;
            background-size: cover;
            height: 80px;
        }

        .cp-quick-panel__close {
            i {
              font-size: 0.8rem;
            }
        }

        .ct-demo-panel__title {
            font-weight: 500;
            color: #ffffff;
            padding-top: 1px;
            padding-left: 30px;
            top: 1rem;
            position: absolute;
        }

        > span {
            display: block;
            @include text-overflow;
        }

        button {
            position: absolute;
            top: 8px;
            right: 8px;
            @include button-square(
                    $size: 32px,
                    $font-size: 20px,
                    $color: white,
                    $focus: false
            );
        }
    }

    footer {
        flex: 0;
        padding: 4px;
        background-color: $pale;

        ::v-deep {
            button {
                margin: 4px;
                @include button($font-size: 15px, $padding: 12px 16px);
            }

            .cancel {
                @include button-light;
            }
        }
    }

    main {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;
    }

    ::v-deep {
        section {
            padding: 8px;

            @media (min-width: $screen-sm) {
                padding: 16px;
            }

            & + section {
                border-top: 1px solid $form-border;
            }
        }
    }
</style>
