<template>
    <full-modal ref="confirmModal" :options="modalOptions">
        <div class="alert alert-light alert-elevate" role="alert">
            <div class="alert-icon"><i class="flaticon-warning"></i></div>
            <div class="alert-text">{{ message }}</div>
        </div>

        <div v-if="extra" class="confirm-extra">
            <label>
                <input type="checkbox" v-model="extraConfirm">
                {{ extra }}
            </label>
        </div>
        <template slot="footer">

            <div class="confirm-footer">
                <div v-if="buttonsSorted.left.length" class="left">
                    <button v-for="button in buttonsSorted.left" type="button" class="btn btn-secondary" @click="action(button.action)">
                        <i class="la la-undo"></i>
                        {{ button.title | translate }}
                    </button>
                </div>
                <div v-if="buttonsSorted.right.length" class="right">
                    <button v-for="button in buttonsSorted.right" type="button" class="btn btn-brand" @click="action(button.action)" :disabled="disabled">
                        <i class="la la-check"></i>
                        {{ button.title | translate }}
                    </button>
                </div>
            </div>

        </template>
    </full-modal>

</template>

<script>
    export default {
        name: 'confirm',
        components: {},
        data() {
            return {
                message: null,
                callback: null,
                extra: null,
                extraConfirm: false,
                modalOptions: {
                    size: 'sm'
                },
                buttons: []
            }
        },
        methods: {
            /**
             * Launches the modal and sets data
             *
             * @params {object} data
             */
            showModal(data) {
                this.message = data.message
                this.callback = data.callback
                this.extra = data.extra
                this.buttons = data.buttons || []
                this.$refs.confirmModal.show()

                if (!this.buttons.length) {
                    this.buttons = [
                        {
                            title: 'confirm',
                            color: 'green',
                            action: 'confirm',
                            callback: this.callback
                        },
                        {
                            title: 'cancel',
                            action: 'cancel',
                            left: true
                        }
                    ]
                }
            },
            /**
             * Closes the modal and clears data
             */
            hideModal() {
                this.message = null
                this.callback = null
                this.extra = null
                this.buttons = []
                this.$refs.confirmModal.hide()
            },
            /**
             * Performs the action callback
             *
             * @params {string} action
             */
            action(action) {
                const button = _.find(this.buttons, {action})
                if (button) {
                    if (_.isFunction(button.callback)) {
                        button.callback()
                    }
                }
                switch (action) {
                    case 'cancel':
                        this.hideModal()
                        break
                    case 'confirm':
                        this.hideModal()
                        break
                }
            }
        },
        computed: {
            /**
             * Prepares button content
             *
             * @returns {object}
             */
            buttonsSorted() {
                let buttonsSorted = {
                    left: [],
                    right: []
                }
                _.each(this.buttons, button => {
                    if (button.left) {
                        buttonsSorted.left.push(button)
                    } else {
                        buttonsSorted.right.push(button)
                    }
                })
                return buttonsSorted
            },
            /**
             * Returns true if extra toggle is unconfirmed
             *
             * @returns {boolean}
             */
            disabled() {
                return (this.extra && !this.extraConfirm) || false
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import "../../sass/variables", "../../sass/mixins";

    .confirm-extra {
        position: relative;
        label {
            display: inline-block;
            padding: 3px 10px 3px 24px;
            font-weight: bold;
        }
        input {
            position: absolute;
            margin-top: 3px;
            margin-left: -20px;
        }
    }

    .confirm-footer {
        padding: 4px;
        background-color: $pale;
        box-shadow: $box-shadow;
        @include clearfix;

        .left {
            float: left;
        }
        .right {
            float: right;
        }

        button {
            margin: 4px;

            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }

            @media (min-width: $screen-sm) {
                @include button;
            }
        }
    }

</style>
