<template>
    <div class="multi-select">
        <div v-if="hasToggle" class="toggle">
            <button type="button" @click="checkAll(true)">{{ 'selectAll' | translate }}</button>
            |
            <button type="button" @click="checkAll(false)">{{ 'deSelectAll' | translate }}</button>
        </div>

        <div class="multi-select-wrapper" ref="multiSelectWrapper">

            <template v-if="!hasTitles">

                <div v-for="(value) in values" :class="inputType">
                    <label :class="{ 'checked': checkedClass(value.id), 'has-subs': hasSubs(value) }">
                        <input :type="inputType" :checked="checkedClass(value.id)" @change="check(value.id)">
                        {{ value.name }}
                    </label>
                    <div v-if="hasSubs(value)" class="indent" v-for="sub in value.subs">
                        <label :class="{ 'checked': checkedClass(sub.id) }">
                            <input :type="inputType" :checked="checkedClass(sub.id)" @change="check(sub.id)">
                            {{ sub.name }}
                        </label>
                    </div>
                </div>

            </template>

            <template v-else>

                <template v-for="(section, index) in values">
                    <div class="title">{{ titles[index] }}</div>
                    <div v-for="value in section" :class="inputType">
                        <label :class="{ 'checked': checkedClass(value.id) }">
                            <input :type="inputType" :checked="checkedClass(value.id)" @change="check(value.id)">
                            {{ value.name }}
                        </label>
                        <div v-if="hasSubs(value)" class="indent" v-for="sub in value.subs">
                            <label :class="{ 'checked': checkedClass(sub.id) }">
                                <input :type="inputType" :checked="checkedClass(sub.id)" @change="check(sub.id)">
                                {{ sub.name }}
                            </label>
                        </div>
                    </div>
                </template>

            </template>
        </div>
    </div>
</template>

<script>
    /**
     * @property {object}  props.values         - Available values.
     * @property {array}   props.selected       - Selected values.
     * @property {object}  props.titles         - (Optional) Section titles.
     * @property {object}  props.options        - (Optional) Component options.
     * @property {object}  props.options.radio  - (Optional) Component displays as radio buttons.
     * @property {object}  props.options.toggle - (Optional) Component shows toggle all.
     */
    export default {
        name: 'multi-select',
        props: [
            'values',
            'selected',
            'titles',
            'options'
        ],
        methods: {
            /**
             * Returns true if checked
             * @param id {string}
             * @returns {boolean}
             */
            checkedClass(id) {
                return _.includes(this.selected, id)
            },
            /**
             * Checks the item and adds focus
             * @param id {string}
             */
            check(id) {
                let selected = this.selected
                if (this.inputType === 'checkbox') {
                    if (this.checkedClass(id)) {
                        selected = _.filter(selected, item => {
                            return item !== id
                        })
                    } else {
                        selected.push(id)
                    }
                } else {
                    selected = [id];
                }
                this.doFocus()
                this.$emit('update', selected)
            },
            /**
             * Check/uncheck all items
             * @param action {boolean}
             */
            checkAll(action) {
                let ids = [];
                /**
                 * Get all items
                 * @param data {object}
                 */
                let getItems = data => {
                    _.each(data, item => {
                        ids.push(item.id)
                        if (this.hasSubs(item)) {
                            _.each(item.subs, subs => {
                                ids.push(subs.id)
                            })
                        }
                    })
                }
                if (action) {
                    if (this.hasTitles) {
                        _.each(this.values, section => {
                            getItems(section)
                        })
                    } else {
                        getItems(this.values)
                    }
                }
                this.$emit('update', ids)
            },
            /**
             * Returns true if item has sub elements
             * @param value {string}
             * @returns {boolean}
             */
            hasSubs(value) {
                return typeof value.subs === 'object' && value.subs.length
            },
            doFocus() {
                let el = this.$refs.multiSelectWrapper
                el.classList.add('focus')
                /**
                 * Detect click outside and stop listener
                 * @param newEvent {object}
                 */
                let clickOut = newEvent => {
                    if (!_.includes(newEvent.path, el)) {
                        el.classList.remove('focus')
                        window.removeEventListener('click', clickOut)
                    }
                }
                window.addEventListener('click', clickOut)
            }
        },
        computed: {
            /**
             * Returns radio or checkbox
             * @returns {string}
             */
            inputType() {
                return this.options && this.options.radio ? 'radio' : 'checkbox'
            },
            /**
             * Returns true if item has titles
             * @returns {boolean}
             */
            hasTitles() {
                return !!this.titles
            },
            /**
             * Returns true if toggle all
             * @returns {string}
             */
            hasToggle() {
                return this.options && this.options.toggle
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../../sass/colors",
    "../../sass/mixins";

    // Variables
    $transition: 120ms;
    $link: darken($app-color, 4%);
    $link-hover: darken($link, 4%);

    .multi-select {
        .toggle {
            text-align: right;
            button {
                margin: 0;
                padding: 0;
                color: $link;
                background-color: transparent;
                border: none;
                font-size: 14px;
                font-weight: bold;
                outline: none;
                cursor: pointer;
                &:hover {
                    color: $link-hover;
                    text-decoration: underline;
                }
            }
        }
        &-wrapper {
            max-height: 250px;
            color: $grey;
            background-color: white;
            border: 1px solid #ddd;
            font-size: 16px;
            overflow-x: hidden;
            overflow-y: auto;
            transition: border-color 240ms, box-shadow 240ms;
            box-shadow: none;
            &.focus {
                border-color: darken($app-color, 5%);
                box-shadow: 0 0 4px darken($app-color, 5%);
            }
        }
        .title {
            padding: 2px 8px;
            color: white;
            background-color: $app-color;
            font-size: 14px;
            line-height: 20px;
            font-weight: bold;
        }
        .checked {
            background-color: mix(transparent, darken($app-color, 5%), 90%);
        }
        label {
            display: block;
            padding: 4px 8px 4px 28px;
            cursor: pointer;
        }
        .indent {
            label {
                padding-left: 40px;
            }
        }
        .has-subs {
            font-weight: bold;
        }
    }
</style>
