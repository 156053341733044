import _each from 'lodash/each'
import _flatMap from 'lodash/flatMap'
import _fromPairs from 'lodash/fromPairs'
import _isArray from 'lodash/isArray'
import _isObject from 'lodash/isObject'
import _map from 'lodash/map'

/**
 * Recursively flattens an object of any depth into an
 * object mapping string keys to scalar values.
 *
 * Arrays are flattened with subscripts, while objects
 * are flattened using dots. Example:
 *
 * {foo: ['bar', 'baz']} -> {'foo[0]': 'bar', 'foo[1]': 'baz'}
 * {foo: {bar: 1, baz: 2}} -> {'foo.bar': 1, 'foo.baz': 2}
 *
 * @param o
 * @returns {Object}
 */
const flattenKeys = o => {
    return _fromPairs(_flatMap(o, (v, k) => {
        if (_isArray(v)) {
            return _map(flattenKeys(v), (sv, sk) => [`${k}[${sk}]`, sv])
        } else if (_isObject(v)) {
            return _map(flattenKeys(v), (sv, sk) => [`${k}.${sk}`, sv])
        } else {
            return [[k, v]]
        }
    }))
}

/**
 * Adds translations properties for Vue components
 */
export default {
    install(Vue) {
        const setTranslations = {}
        Vue.translations = add => Object.assign(setTranslations, flattenKeys(add))
        /**
         * Replace {matching} in string
         * @param msg
         * @param args
         * @returns {string}
         */
        Vue.translate = (msg, args = false) => {
            let translated = setTranslations[msg] || '\'' + msg + '\''
            if (args) {
                const keys = translated.match(/\{.*?\}/g)
                _each(keys, key => {
                    const value = args[key.replace(/\{|\}/g, '')] || ''
                    translated = translated.replace(key, value)
                })
            }
            return translated
        }
        Vue.filter('translate', Vue.translate)
    }
}
